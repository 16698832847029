import { request } from 'graphql-request';
import { CONTENTFUL_URL } from '../../../../config/contentful-config';

export const getTotalNumberOfInterviewsFromContentfulViaGql =
  async (): Promise<number> => {
    const query = `{
    interviewCollection {
      total
    }
  }`;

    const response = await request(CONTENTFUL_URL, query);
    return response.interviewCollection.total;
  };
