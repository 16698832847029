import * as iso from 'iso-3166-1';
import { getContentfulClient } from '../../config/contentful-config';

const getCountryCode = async (countryCode: string): Promise<string | null> => {
  let countryCodeAlpha3 = countryCode;
  if (countryCode.length === 2) {
    const countryObject = iso.whereAlpha2(countryCode);
    if (countryObject === undefined) {
      return null;
    }
    countryCodeAlpha3 = countryObject.alpha3;
  }

  const contentfulClient = getContentfulClient();

  const response = await contentfulClient.getEntries({
    content_type: 'country',
    'fields.countryCode': countryCodeAlpha3,
  });

  if (response.items.length === 0) {
    return null;
  }

  return response.items[0].sys.id;
};

export const getUnauthorisedRightsAgreementsFromContentful = async (
  countryCode: string
): Promise<string[]> => {
  const countryCodeId = await getCountryCode(countryCode);
  if (countryCodeId === null) {
    return [];
  }

  const contentfulClient = getContentfulClient();

  const notAllowedResponse = await contentfulClient.getEntries({
    content_type: 'rightsAgreement',
    'fields.relatedCountries.sys.id[nin]': countryCodeId,
    'fields.restrictionType': 'Allow',
  });

  const deniedResponse = await contentfulClient.getEntries({
    content_type: 'rightsAgreement',
    'fields.relatedCountries.sys.id': countryCodeId,
    'fields.restrictionType': 'Deny',
  });

  const response = [...notAllowedResponse.items, ...deniedResponse.items];

  return response.map((item) => item.sys.id);
};
