import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import searchReducer from './search/search.slice';
import seriesReducer from './series/series.slice';
import notificationReducer from './notifications/notifications.slice';
import companyReducer from './company/company.slice';

export const store = configureStore({
  reducer: {
    search: searchReducer,
    user: userReducer,
    series: seriesReducer,
    notifications: notificationReducer,
    company: companyReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
