/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type Group from '../../accounts/data/group';
import { INITIAL_COMPANY_STATE } from './company.state';

export const companySlice = createSlice({
  name: 'company',
  initialState: INITIAL_COMPANY_STATE,
  reducers: {
    setGroups: (state, action: PayloadAction<{ groups: Group[] }>) => {
      state.groups = action.payload.groups;
    },
    setRestrictAccessToAdultThemes: (
      state,
      action: PayloadAction<{
        id: string;
        restrictAccessToAdultThemes: boolean;
      }>
    ) => {
      const item = state.groups.find((group) => group.id === action.payload.id);
      if (item) {
        item.restrictAccessToAdultThemes =
          action.payload.restrictAccessToAdultThemes;
      }
    },
    removeContent: (
      state,
      action: PayloadAction<{
        id: string;
        content: string;
        status: 'allowed' | 'blocked';
      }>
    ) => {
      const item = state.groups.find((group) => group.id === action.payload.id);
      if (item?.alwaysBlockedContent && action.payload.status === 'blocked') {
        item.alwaysBlockedContent = item.alwaysBlockedContent.filter(
          (content) => content !== action.payload.content
        );
      }
      if (item?.alwaysAllowedContent && action.payload.status === 'allowed') {
        item.alwaysAllowedContent = item.alwaysAllowedContent.filter(
          (content) => content !== action.payload.content
        );
      }
    },
    addContent: (
      state,
      action: PayloadAction<{
        id: string;
        content: string[];
        status: 'allowed' | 'blocked';
      }>
    ) => {
      const item = state.groups.find((group) => group.id === action.payload.id);
      if (item?.alwaysBlockedContent && action.payload.status === 'blocked') {
        item.alwaysBlockedContent = action.payload.content;
      }
      if (item?.alwaysAllowedContent && action.payload.status === 'allowed') {
        item.alwaysAllowedContent = action.payload.content;
      }
    },
  },
});

export const {
  setGroups,
  setRestrictAccessToAdultThemes,
  removeContent,
  addContent,
} = companySlice.actions;

export default companySlice.reducer;
