import URI from 'urijs';
import type { DirectoryOptions } from '../../shared/data/directory';
import getFromBff from '../../respositories/bff';
import type { Aggregations } from '../../elastic-search/data/aggregations';

type GenericBffResponse<T> = {
  items: T[];
  total: number;
  aggregations: Aggregations;
};

const getContentFromBff = <T>(resourceName: string) => {
  return async (options?: DirectoryOptions): Promise<GenericBffResponse<T>> => {
    const apiUrl = URI(`${window.location.origin}/api/${resourceName}`);

    if (options?.limit !== undefined) {
      apiUrl.addQuery('limit', options.limit);
    }

    if (options?.skip !== undefined) {
      apiUrl.addQuery('skip', options.skip);
    }

    if (options?.order) {
      apiUrl.addQuery('sort_by', options.order);
    }

    if (options?.searchTerm) {
      apiUrl.addQuery('searchTerm', options.searchTerm);
    }

    options?.aggregations?.forEach((aggregation) => {
      apiUrl.addQuery('aggregations', aggregation);
    });

    options?.filters.forEach((filter) => {
      apiUrl.addQuery(filter.field, filter.values);
    });

    const response = await getFromBff(apiUrl.toString());
    const { items, total, aggregations } = await response.json();

    return {
      items,
      total,
      aggregations,
    } as GenericBffResponse<T>;
  };
};

export default getContentFromBff;
