export enum MediaBreakpoints {
  UNKNOWN = 0,
  XS = 1,
  SM = 2,
  SE = 3,
  IM = 4,
  MD = 5,
  LG = 6,
  XL = 7,
}
