import type { ContentfulClientApi } from 'contentful';
import { getEntries } from '../../../respositories/frontend/contentful-repository';
import { CONTENTFUL_CONTENT_TYPE } from './types';
import toContentfulOrdering from '../../../utils/to-contentful-ordering';
import type { Period } from '../../data/period';
import { mapSdkToPeriod } from './map-sdk-to-period';

export function getPeriodsForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: string[]): Promise<Period[]> => {
    const periodResponse = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.PERIOD,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const mappedPeriods = periodResponse.items.map(mapSdkToPeriod);

    return toContentfulOrdering(slugs, mappedPeriods);
  };
}
