import type { ContentfulClientApi } from 'contentful';
import { getEntries } from '../../../respositories/frontend/contentful-repository';
import type { GetNumberOfInterviewsAndWorkshopsAssociatedWithContent } from '../types';

export const newGetNumberOfInterviewsAndWorkshopsAssociatedWithContentFromContentfulViaSdk =
  (
    client: ContentfulClientApi
  ): GetNumberOfInterviewsAndWorkshopsAssociatedWithContent => {
    return async (content, associatedContentType): Promise<number> => {
      const interviewsWithContent = await getEntries(
        {
          content_type: 'interview',
          [associatedContentType]: content.id,
          limit: 0,
        },
        client
      );
      const workshopsWithContent = await getEntries(
        {
          content_type: 'workshop',
          [associatedContentType]: content.id,
          limit: 0,
        },
        client
      );

      const total = interviewsWithContent.total + workshopsWithContent.total;
      return total;
    };
  };
