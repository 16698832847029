export interface Organisation {
  parentCompanyId: number;
  id: string;
  name: string;
  type: string;
  category: string;
  adultThemesRestricted: boolean;
  country: string;
  country_alpha3: string;
  authMethod: string;
  blockedProfiles: Array<string>;
}

export interface SubscriptionsStatus {
  hasActiveFullSubscription: boolean;
  hasActiveTrialSubscription: boolean;
  hasExpiredSubscription: boolean;
  hasSubscriptionThatWillStartInFuture: boolean;
}

export interface Subscription {
  id: string;
  startDate: number;
  endDate: number;
  type: string;
  requestFeedback: boolean;
  suppressExpiryMsg: boolean;
}

// The highest subscription state the user possesses
export enum SubscriptionState {
  FULL,
  TRIAL,
  FUTURE,
  EXPIRED,
  NONE,
}

export interface MasterAccount {
  id: number;
  name: string;
  type: string;
  category: string;
  country: string;
}

export interface Permissions {
  superuser: boolean;
  administrator: string[];
  teacher: string[];
  finance: string[];
  librarian: string[];
  technical: string[];
  faculty: string[];
  other: string[];
}
