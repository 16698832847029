import fetch from 'isomorphic-unfetch';

import type { GetOrganisation } from '../types';

export const getOrganisationFromDt: GetOrganisation = async (
  userID: string
) => {
  const userAPI = `${window.location.origin}/api/users/${userID}`;
  const response = await fetch(userAPI);
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new Error(jsonResponse.error.message);
  }

  return jsonResponse;
};

export const getIpOrganisationFromDt: GetOrganisation = async (
  organisationID: string
) => {
  const orgAPI = `${window.location.origin}/api/organisations/${organisationID}`;
  const response = await fetch(orgAPI);
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new Error(jsonResponse.error.message);
  }

  return jsonResponse;
};
