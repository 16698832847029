import type { Entry } from 'contentful';
import mapSdkToGuide from '../../../../guides/api/frontend/adapters/mappers/map-sdk-to-guide';
import { mapSdkToInterview } from '../../../../interviews/api/frontend/adapters/mappers/map-sdk-to-interview';
import mapSdkToLessonPlan from '../../../../lesson-plans/api/frontend/adapters/mappers/map-sdk-to-lesson-plan';
import { mapSdkResponseToPerson } from '../../../../people/api/frontend/adapters/contentful/mappers/map-sdk-to-person';
import { mapSdkToProduction } from '../../../../productions/api/frontend/adapters/mappers/map-sdk-to-production';
import { mapSdkToSeries } from '../../../../series/api/frontend/adapters/mappers/map-sdk-to-series';
import { mapSdkResponseToTitle } from '../../../../titles/api/frontend/adapters/mappers/map-sdk-to-title';
import { mapSdkToWorkshop } from '../../../../workshops/api/frontend/adapters/mappers/map-sdk-to-workshop';
import type { Content } from '../../../../content/data/content';
import { ConsoleLogger } from '../../../services/logging/console-logger';

const logger = new ConsoleLogger();

const contentMappers = {
  title: mapSdkResponseToTitle,
  production: mapSdkToProduction,
  person: mapSdkResponseToPerson,
  interview: mapSdkToInterview,
  workshop: mapSdkToWorkshop,
  guide: mapSdkToGuide,
  lessonPlan: mapSdkToLessonPlan,
  series: mapSdkToSeries,
};

export const mapContentfulEntriesToContents = (
  contentfulObjects: Entry<any>[]
): Content[] => {
  const contents: Content[] = [];

  contentfulObjects.forEach((contentfulEntry) => {
    const type = contentfulEntry.sys.contentType.sys.id;
    const mapper = contentMappers[type];
    if (mapper) {
      contents.push({ type, value: mapper(contentfulEntry) } as Content);
    } else {
      logger.error(`contentType "${type}" not recognised`);
    }
  });
  return contents;
};
