import dayjs from 'dayjs';
import type { Subscription } from '../../auth/data/user';
import {
  getAllSubscriptionsByType,
  getActiveSubscriptionsByType,
  latestSubscription,
} from '../../auth/utils/subscriptions';
import type { UsatSurveyMetadata } from '../data/usat-survey-metadata';
import { ConsoleLogger } from '../services/logging/console-logger';

const logger = new ConsoleLogger();

const shouldActivateWrapper = (func) => {
  return (...args) => {
    try {
      return func(...args);
    } catch (error) {
      logger.error(error);
      return false;
    }
  };
};

export const shouldActivateSubscriptionBanner = shouldActivateWrapper(
  (
    userId: string,
    subscriptions: Subscription[],
    userNotificationData: UsatSurveyMetadata | undefined
  ): boolean => {
    if (userId === '') {
      return false;
    }

    const activeFullSubscriptions = getActiveSubscriptionsByType(
      subscriptions,
      'full'
    );
    if (!activeFullSubscriptions || activeFullSubscriptions.length === 0) {
      return false;
    }

    const fullSubscriptions = getAllSubscriptionsByType(subscriptions, 'full');
    if (!fullSubscriptions || fullSubscriptions.length === 0) {
      return false;
    }

    const lastSubscription = latestSubscription(fullSubscriptions);
    if (lastSubscription === null || lastSubscription.suppressExpiryMsg) {
      return false;
    }

    const endDate = dayjs(lastSubscription.endDate);
    const diff = endDate.diff(dayjs(), 'days');

    if (diff > 90 || diff < 0) {
      return false;
    }

    return userNotificationData
      ? dayjs(userNotificationData.nextDisplayTimestamp).isBefore(dayjs())
      : true;
  }
);

const isLessThanThreeMonthsAgo = (date: number): boolean => {
  const threeMonthsFromStartDate = dayjs(date).add(3, 'months');

  return dayjs().isBefore(threeMonthsFromStartDate);
};

export const shouldActivateSurveyBanner = shouldActivateWrapper(
  (
    userId: string,
    subscriptions: Subscription[],
    usatSurveyMetadata: UsatSurveyMetadata
  ): boolean => {
    const fullSubscriptions = getActiveSubscriptionsByType(
      subscriptions,
      'full'
    );

    if (userId === '' || fullSubscriptions.length === 0) {
      return false;
    }

    const { startDate } = fullSubscriptions[0];

    if (isLessThanThreeMonthsAgo(startDate)) {
      return false;
    }

    return usatSurveyMetadata.nextDisplayTimestamp.isBefore(dayjs());
  }
);
