import assert from 'assert';
import { ConsoleLogger } from '../../../services/logging/console-logger';
import type { GetGranularContentControls } from '../../types';

const customersApiUrl = process.env.NEXT_PUBLIC_CUSTOMERS_API_URL;
assert.ok(
  customersApiUrl,
  'Missing required .env variable: "NEXT_PUBLIC_CUSTOMERS_API_URL"'
);

export const getGranularContentControlsFromUsersApi: GetGranularContentControls =
  async (organisationId: string) => {
    const url = `${customersApiUrl}/organisations/${organisationId}`;

    const response = await fetch(url);

    if (!response.ok) {
      const { message } = await response.json();
      new ConsoleLogger().error(message);

      return {
        allowedContentIds: [],
        blockedContentIds: [],
      };
    }

    const { organisation } = await response.json();

    return {
      allowedContentIds: organisation.allowedContent,
      blockedContentIds: organisation.blockedContent,
    };
  };
