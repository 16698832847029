import { init, push } from '@socialgouv/matomo-next';
import dayjs from 'dayjs';
import type {
  MasterAccount,
  Organisation,
  Permissions,
  Subscription,
} from '../../../auth/data/user';
import { getEducationLevel } from '../../../auth/utils/get-education-level';
import { permissionsToArray } from '../../../auth/utils/permissions';
import {
  ClickedFromPageTitle,
  ClickedFromPageType,
  TrackedEvent,
} from '../../data/events';
import { normaliseDimensions } from '../../utils/normalise-dimensions';
import type {
  ResetAnalytics,
  ResetContentImpressionTrackers,
  SetupTracker,
  TrackContentImpression,
  TrackMasterAccount,
  TrackMediaAnalytics,
  TrackOrganisation,
  TrackSubscriptions,
  TrackUser,
} from '../types';

export const setupTrackerWithMatomo: SetupTracker = () => {
  if (
    process.env.NEXT_PUBLIC_MATOMO_URL &&
    process.env.NEXT_PUBLIC_MATOMO_SITE_ID
  ) {
    init({
      url: process.env.NEXT_PUBLIC_MATOMO_URL,
      siteId: process.env.NEXT_PUBLIC_MATOMO_SITE_ID,
    });
  }
  push(['trackVisibleContentImpressions']);
  push(['enableHeartBeatTimer']);
};

export const trackUserWithMatomo: TrackUser = (id: string) => {
  push(['setUserId', id]);
};

export const trackMasterAccountWithMatomo: TrackMasterAccount = (
  masterAccount: MasterAccount
) => {
  push(['setCustomDimension', 1, masterAccount.id]);
  push(['setCustomDimension', 2, masterAccount.name]);
};

export const trackOrganisationWithMatomo: TrackOrganisation = (
  organisation: Partial<Organisation>
) => {
  if (organisation.id) {
    push(['setCustomDimension', 3, organisation.id]);
  }

  if (organisation.name) {
    push([
      'setCustomDimension',
      4,
      `${organisation.name}${organisation.id ? ` (${organisation.id})` : ''}`,
    ]);
  }

  if (organisation.country) {
    push(['setCustomDimension', 5, organisation.country]);
  }

  if (organisation.type) {
    push(['setCustomDimension', 6, organisation.type]);
  }

  if (organisation.category) {
    push(['setCustomDimension', 7, organisation.category]);
  }

  if (organisation.authMethod) {
    push(['setCustomDimension', 11, organisation.authMethod]);
  }

  if (organisation.category) {
    push(['setCustomDimension', 22, getEducationLevel(organisation.category)]);
  }
};

export const trackUserPermissionsWithMatomo = (
  permissions: (Permissions & Record<string, unknown>) | undefined
) => {
  if (permissions !== undefined) {
    const permissionsArray = permissionsToArray(permissions);
    if (permissionsArray.length > 0) {
      push(['setCustomDimension', 23, permissionsArray.sort().join(', ')]);
      return;
    }
  }
  push(['setCustomDimension', 23, 'unknown']);
};

export const resetContentImpressionTrackersWithMatomo: ResetContentImpressionTrackers =
  () => {
    push(['deleteCustomDimension', 8]);
    push(['deleteCustomDimension', 9]);
    push(['deleteCustomDimension', 10]);
    push(['deleteCustomDimension', 13]);
    push(['deleteCustomDimension', 21]);
  };

export const trackContentImpressionWithMatomo: TrackContentImpression = (
  id: string,
  producer: string,
  title: string,
  contentType: string,
  subType: string
) => {
  push(['setCustomDimension', 8, id]);
  push(['setCustomDimension', 9, producer]);
  push(['setCustomDimension', 10, contentType]);
  push(['setCustomDimension', 13, subType.toLowerCase()]);
  push([
    'trackContentImpression',
    `${title} (${id})`,
    // eslint-disable-next-line no-restricted-globals
    location.href,
    '',
  ]);
  resetContentImpressionTrackersWithMatomo();
};

export const trackMediaWithMatomo: TrackMediaAnalytics = (
  id: string,
  producer: string,
  title: string,
  contentType: string,
  subType: string,
  playedFromPlaylist: string | null
) => {
  push(['setCustomDimension', 8, id]);
  push(['setCustomDimension', 9, producer]);
  push(['setCustomDimension', 10, contentType]);
  push(['setCustomDimension', 13, subType.toLowerCase()]);
  if (playedFromPlaylist) {
    push(['setCustomDimension', 21, playedFromPlaylist.toLowerCase()]);
  }
  push(['MediaAnalytics::scanForMedia']);
  setTimeout(
    () =>
      push([
        'trackContentImpression',
        `${title} (${id})`,
        // eslint-disable-next-line no-restricted-globals
        location.href,
        '',
      ]),
    5000
  );
};

export const resetAnalyticsWithMatomo: ResetAnalytics = () => {
  push(['resetUserId']);
  push(['deleteCustomDimension', 1]);
  push(['deleteCustomDimension', 2]);
  push(['deleteCustomDimension', 3]);
  push(['deleteCustomDimension', 4]);
  push(['deleteCustomDimension', 5]);
  push(['deleteCustomDimension', 6]);
  push(['deleteCustomDimension', 7]);
  push(['deleteCustomDimension', 8]);
  push(['deleteCustomDimension', 9]);
  push(['deleteCustomDimension', 10]);
  push(['deleteCustomDimension', 11]);
  push(['deleteCustomDimension', 12]);
  push(['deleteCustomDimension', 22]);
  push(['deleteCustomDimension', 23]);
};

const checkSubscriptionsStatus = (subscriptions: Subscription[]): string => {
  const today = dayjs().valueOf();
  let fullSubscriptionExists = false;
  let trialSubscriptionExists = false;
  subscriptions.forEach((sub: Subscription) => {
    const { startDate, endDate } = sub;
    if (endDate > today) {
      if (startDate < today) {
        switch (sub.type) {
          case 'full':
            fullSubscriptionExists = true;
            break;
          case 'trial':
            trialSubscriptionExists = true;
            break;
          default:
            break;
        }
      }
    }
  });

  if (fullSubscriptionExists) {
    return 'full';
  }
  if (trialSubscriptionExists) {
    return 'trial';
  }
  return 'none';
};

export const trackSubscriptionsWithMatomo: TrackSubscriptions = (
  subscriptions: Subscription[]
) => {
  const subscriptionType = checkSubscriptionsStatus(subscriptions);

  push(['setCustomDimension', 12, subscriptionType]);
};

export const trackEventWithMatomo = (event: TrackedEvent) => {
  if (
    !event ||
    !event.category ||
    !event.action ||
    !event.name ||
    event.clickedFromPageTitle === ClickedFromPageTitle.UNKNOWN ||
    event.clickedFromPageType === ClickedFromPageType.UNKNOWN
  ) {
    return;
  }

  const { category, action, name, value = 1, ...dimensions } = event;

  const normalisedDimensions: { [key: string]: string } =
    normaliseDimensions(dimensions);

  // eslint-disable-next-line no-underscore-dangle
  window._paq.push([
    'trackEvent',
    category,
    action,
    name,
    value,
    normalisedDimensions,
  ]);
};
