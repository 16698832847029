import type { ContentType } from '../../content/data/content-type';
import type { SlugValue } from '../../shared/hooks/use-slug';

export interface EntityKey {
  type: ContentType;
  slug: SlugValue;
}

export enum EntityLoadingStatus {
  UNINITIALISED = 'UNINITIALISED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  REFRESHING = 'REFRESHING',
  ERROR = 'ERROR',
}

export interface EntityWrapper<T> {
  key: SlugValue | undefined;
  loadingStatus: EntityLoadingStatus;
  cacheUntil: number | undefined;
  item: T | undefined;
  error: string | null;
}
