import { createContext } from 'react';
import type { TourStep } from './types/tour-step';

export interface TourContextValue {
  tourSteps: TourStep[];
  currentStep: number;
  startTour: () => void;
  nextStep: () => void;
  prevStep: () => void;
  quitTour: () => void;
  started: boolean;
  setUpTour: (steps: TourStep[], pageTitle: string, pageType: string) => void;
}

export const TourContext = createContext<TourContextValue>({
  tourSteps: [],
  currentStep: 0,
  startTour: () => {},
  nextStep: () => {},
  prevStep: () => {},
  quitTour: () => {},
  started: false,
  setUpTour: () => {},
});
