import { getContentfulClient } from '../../config/contentful-config';
import { getCountryCodeFromStore } from '../../utils/get-country-code-from-store';
import { getBlockedProfilesFromStore } from '../../utils/get-blocked-profiles-from-store';
import {
  type ContentfulQuery,
  getEntriesIsomorphic,
  type GetEntriesResponse,
} from '../isomorphic/contentful-repository';

export const getEntries = async <T extends ContentfulQuery>(
  options: T,
  client = getContentfulClient()
): GetEntriesResponse => {
  const countryCode = await getCountryCodeFromStore();
  const blockedProfiles = await getBlockedProfilesFromStore();

  return getEntriesIsomorphic(options, client, {
    countryCode,
    blockedProfiles,
  });
};
