import fetch from 'isomorphic-unfetch';

import type { GetMyIpAddress } from '../types';

export const getMyIpAddressFromDt: GetMyIpAddress = async () => {
  const response = await fetch(`${window.location.origin}/api/what-is-my-ip`);

  const ip = await response.text();
  return ip;
};
