import { getGuide } from '../../../../guides/api/frontend';
import { getInterview } from '../../../../interviews/api/frontend';
import { getLessonPlan } from '../../../../lesson-plans/api/frontend';
import { getProduction } from '../../../../productions/api/frontend';
import { getWorkshop } from '../../../../workshops/api/frontend';
import type { Content } from '../../../../content/data/content';
import type {
  SeriesRelatedContent,
} from '../../../data/series-related-content';
import { ContentType } from '../../../../content/data/content-type';

export const getSeriesRelatedContentFromContentful = async (
  seriesRelatedContent: SeriesRelatedContent
): Promise<Content | null> => {
  try {
    switch (seriesRelatedContent.type) {
      case 'production':
        return {
          type: ContentType.PRODUCTION,
          value: await getProduction(seriesRelatedContent.slug),
        };
      case 'interview':
        return {
          type: ContentType.INTERVIEW,
          value: await getInterview(seriesRelatedContent.slug),
        };
      case 'workshop':
        return {
          type: ContentType.WORKSHOP,
          value: await getWorkshop(seriesRelatedContent.slug),
        };
      case 'guide':
        return {
          type: ContentType.GUIDE,
          value: await getGuide(seriesRelatedContent.slug),
        };
      case 'lessonPlan':
        return {
          type: ContentType.LESSON_PLAN,
          value: await getLessonPlan(seriesRelatedContent.slug),
        };
      default:
    }
  } catch (e) {
    // Can be failing if the related content slug is filtered by blocked content profiles.
    return null;
  }

  throw new Error(`Content type ${seriesRelatedContent.type} is not part of a series`);
};
