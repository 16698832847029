import type { ContentfulClientApi } from 'contentful';
import type { FilterOption } from '../../data/filter';

export enum CONTENTFUL_SORT {
  ASCENDING = 'name-asc',
  DESCENDING = 'name-desc',
  RELEVANCE = 'rel-desc',
  NEWEST = 'age-desc',
  OLDEST = 'age-asc',
}

export enum CONTENTFUL_CONTENT_TYPE {
  TITLE = 'title',
  PRODUCTION = 'production',
  GUIDE = 'guide',
  THEME = 'theme',
  INTERVIEW = 'interview',
  WORKSHOP = 'workshop',
  GENRE = 'genre',
  LESSON_PLAN = 'lessonPlan',
  PERSON = 'person',
  ROLE = 'role',
  SERIES = 'series',
  SKILL = 'skill',
  COLLECTION = 'collection',
  COMPANY = 'company',
  PERIOD = 'period',
  EDUCATION_LEVEL = 'educationLevel',
  STUDY_UNIT = 'studyUnit',
  STUDY_UNIT_MODULE = 'studyUnitModule',
  STUDY_UNIT_SECTION = 'studyUnitSection',
  TOPIC = 'topic',
}

export enum CONTENTFUL_RELATED_CONTENT_TYPE {
  GENRES = 'fields.relatedGenres.sys.id',
  THEMES = 'fields.relatedThemes.sys.id',
  CONTENT = 'fields.relatedContent.sys.id',
  ROLES = 'fields.relatedRoles.sys.id',
}

export type GetEntriesResponse = {
  items: any;
  total: number;
};

export type GetEntriesOptions = {
  skip?: number;
  limit?: number;
  order?: string;
  filters?: FilterOption[];
  include?: number;
  curated?: boolean;
};

export type GetEntriesFromContentful = (
  content_type: string,
  client: ContentfulClientApi,
  options?: GetEntriesOptions
) => Promise<GetEntriesResponse>;
