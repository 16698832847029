import dayjs from 'dayjs';
import {
  NotificationArea,
  NotificationAreas,
  Notification,
  NotificationPriority,
  NotificationType,
} from '../../ui/notifications/data/notifications';

export const INITIAL_NOTIFICATIONS_STATE: NotificationAreas = {};

export const INITIAL_NOTIFICATION_AREA_STATE: NotificationArea = {
  name: '',
  notifications: {},
};

export const INITIAL_NOTIFICATION_STATE: Notification = {
  id: '',
  data: null,
  config: {
    priority: NotificationPriority.NORMAL,
    type: NotificationType.MESSAGE,
    active: true,
    dismissable: true,
    autoDismiss: false,
    dismissAfter: 10000,
  },
  state: {
    show: true,
    dismissed: false,
    timestamp: dayjs().unix(),
  },
};
