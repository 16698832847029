/**
 * Convert a string into a kebab-case suitable for HTML IDs etc.
 * Also trims any non-alphanumeric characters at the start and end of the string.
 *
 * E.g. toKebabCase('Help and Support') -> 'help-and-support'
 */
function toKebabCase(string: string): string {
  return string
    .normalize('NFD')
    .replace(/\p{Diacritic}|[^a-z0-9A-Z| |-]/gu, '')
    .trim()
    .replace(/ {2,}| /g, '-')
    .toLowerCase();
}

export default toKebabCase;
