import assert from 'assert';
import { createApolloClient } from './create-apollo-client';

export default {
  v2: {
    createApolloClient: (accessToken: string, dtApi?: string) => {
      assert(process.env.GRAPHQL_API_URL, 'MISSING ENV: GRAPHQL_API_URL');
      return createApolloClient(
        accessToken,
        dtApi,
        process.env.GRAPHQL_API_URL
      );
    },
  },
  v3: {
    createApolloClient: (accessToken: string, dtApi?: string) => {
      assert(
        process.env.NEXT_PUBLIC_CUSTOMER_API_V3_URL,
        'MISSING ENV: NEXT_PUBLIC_CUSTOMER_API_V3_URL'
      );
      return createApolloClient(
        accessToken,
        dtApi,
        process.env.NEXT_PUBLIC_CUSTOMER_API_V3_URL
      );
    },
  },
};
