export const mutateUserNotificationsDataFromCustomerGql = async (
  userId,
  notifications
) => {
  const response = await fetch(`/api/users/${userId}/notifications`, {
    method: 'POST',
    body: JSON.stringify({ notifications }, null, 2),
  });

  const data = await response.json();

  return data;
};
