export enum NotificationPriority {
  LOWEST = 0,
  LOW = 2,
  NORMAL = 5,
  HIGH = 8,
  HIGHEST = 10,
}

export enum NotificationType {
  TEMPLATE = 'TEMPLATE',
  MESSAGE = 'MESSAGE',
}

export interface NotificationConfig {
  priority: NotificationPriority;
  type: NotificationType;
  active: boolean;
  dismissable: boolean;
  autoDismiss: boolean;
  dismissAfter: number;
}

export interface NotificationState {
  show: boolean;
  dismissed: boolean;
  timestamp: number;
}

export interface Notification {
  id: string;
  data: unknown;
  config: NotificationConfig;
  state: NotificationState;
}

export interface NotificationMessage {
  message: string;
}

export interface NotificationArea {
  name: string;
  notifications: Record<string, Notification>;
}

export interface NotificationAreas {
  [key: string]: NotificationArea;
}
