import { useEffect, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { setChildInteractivity } from '../../../utils/dom-interactivity';
import {
  notificationSelector,
  notificationShowSelector,
} from '../../../store/notifications/selectors';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import {
  addNotification,
  dismissNotificationInArea,
  setNotificationActiveInArea,
  setNotificationInactiveInArea,
  showNotificationInArea,
} from '../../../store/notifications/notifications.slice';
import { useNotificationAreaContext } from '../../../ui/notifications/context/notification-area-context';
import {
  NotificationPriority,
  NotificationType,
} from '../../../ui/notifications/data/notifications';
import { ConsoleLogger } from '../../services/logging/console-logger';

const logger = new ConsoleLogger();

interface Props {
  name: string;
  active?: boolean;
  priority?: NotificationPriority;
  onDismiss?: () => void;
  dismissable?: boolean;
  children: ReactNode;
  top?: boolean;
  bottom?: boolean;
  dismissTestId?: string;
  autoDismiss?: boolean;
  ariaLabel: string;
}

const NotificationBanner: FC<Props> = ({
  name,
  active = true,
  priority = NotificationPriority.LOW,
  onDismiss,
  dismissable,
  children,
  bottom,
  dismissTestId,
  autoDismiss,
  ariaLabel,
}: Props) => {
  const areaName = useNotificationAreaContext();
  const notification = useTypedSelector((state) =>
    notificationSelector(state, areaName, name)
  );
  const dispatch = useAppDispatch();

  const expanded = useTypedSelector((state) =>
    notificationShowSelector(state, areaName, name)
  );
  const setExpanded = (newExpandedValue: boolean) => {
    if (newExpandedValue) {
      dispatch(
        showNotificationInArea({
          area: areaName!,
          name,
        })
      );
    } else {
      dispatch(
        dismissNotificationInArea({
          area: areaName!,
          name,
        })
      );
    }
  };

  const banner = useRef<HTMLDivElement>(null);
  const setHeight = (): void => {
    const dismissed = banner.current!.getAttribute('aria-hidden') === 'true';
    banner.current!.style.height = '';
    banner.current!.style.height = `${
      dismissed ? 0 : banner.current!.offsetHeight
    }px`;
    if (!bottom) {
      document.documentElement.style.setProperty(
        '--notification-banner-h',
        `${dismissed ? 0 : banner.current!.clientHeight}px`
      );
    }
  };

  useEffect(() => {
    if (!notification && areaName) {
      dispatch(
        addNotification({
          area: areaName,
          name,
          data: null,
          config: {
            priority,
            type: NotificationType.TEMPLATE,
            dismissable: dismissable ?? true,
            active,
            autoDismiss: autoDismiss ?? false,
            dismissAfter: 0,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (areaName && notification) {
      if (active) {
        dispatch(setNotificationActiveInArea({ area: areaName, name }));
      } else {
        dispatch(setNotificationInactiveInArea({ area: areaName, name }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, areaName, notification]);

  useEffect(() => {
    window.addEventListener('resize', setHeight);

    return (): void => {
      window.removeEventListener('resize', setHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeight();
    setChildInteractivity(banner.current!, expanded ?? false);
    document.documentElement.classList.toggle(
      `notification-banner-visible-${areaName}`,
      expanded
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  useEffect(() => {
    if (!autoDismiss) {
      setHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    let triggerTime = 0;
    let timeoutReferenceId: number;
    let remainingTime = 12100;

    const handleMouseLeave = () => {
      triggerTime = new Date().getTime();

      timeoutReferenceId = window.setTimeout(() => {
        setExpanded(!expanded);
      }, remainingTime);
    };

    const handleMouseEnter = () => {
      window.clearTimeout(timeoutReferenceId);
      remainingTime -= new Date().getTime() - triggerTime;
    };

    if (autoDismiss) {
      banner!.current!.addEventListener('mouseleave', handleMouseLeave);
      banner!.current!.addEventListener('mouseenter', handleMouseEnter);
    }

    return () => {
      window.clearTimeout(timeoutReferenceId);
      banner!.current?.removeEventListener('mouseleave', handleMouseLeave);
      banner!.current?.removeEventListener('mouseenter', handleMouseEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoDismiss]);

  if (areaName === undefined) {
    logger.log('This component must be used inside a notification area');
  }

  if (expanded === undefined) {
    logger.log('No notification exists in the store');
  }

  function dismissOnClickHandler(): void {
    setExpanded(!expanded);
    onDismiss?.();
  }

  return (
    <section
      className={`c-notification-banner 
      ${name === 'subscriptionExpiryBanner' ? 'u-bg-yellow' : 'u-bg-green'}
      ${bottom ? 'c-notification-banner--bottom' : ''} ${
        autoDismiss ? 'c-notification-banner--auto-dismissing' : ''
      }`}
      ref={banner}
      aria-hidden={!expanded}
      data-testid="notification-banner"
      id="notification-banner"
      aria-label={ariaLabel}
    >
      <div className="c-notification-banner__inner">
        <div className="s-rtf s-rtf--dark u-mt-1 u-flex-grow u-mr-auto">
          {children}
        </div>

        {dismissable && (
          <button
            type="button"
            onClick={dismissOnClickHandler}
            className="c-square-button c-square-button--v-dark c-square-button--close u-ml-4"
            data-testid={dismissTestId}
          >
            <div />
            <span className="u-sr-only">Dismiss</span>
            <div />
          </button>
        )}
      </div>
    </section>
  );
};

export default NotificationBanner;
