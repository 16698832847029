import {
  resetAnalyticsWithMatomo,
  resetContentImpressionTrackersWithMatomo,
  setupTrackerWithMatomo,
  trackContentImpressionWithMatomo,
  trackEventWithMatomo,
  trackMasterAccountWithMatomo,
  trackMediaWithMatomo,
  trackOrganisationWithMatomo,
  trackSubscriptionsWithMatomo,
  trackUserPermissionsWithMatomo,
  trackUserWithMatomo,
} from './adapters/matomo-analytics-api';
import type {
  ResetAnalytics,
  ResetContentImpressionTrackers,
  SetupTracker,
  TrackContentImpression,
  TrackEvent,
  TrackMasterAccount,
  TrackMediaAnalytics,
  TrackOrganisation,
  TrackSubscriptions,
  TrackUser,
  TrackUserPermissions,
} from './types';

export const setupTracker: SetupTracker = setupTrackerWithMatomo;

export const trackUser: TrackUser = trackUserWithMatomo;

export const trackMasterAccount: TrackMasterAccount =
  trackMasterAccountWithMatomo;

export const trackOrganisation: TrackOrganisation = trackOrganisationWithMatomo;

export const trackContentImpression: TrackContentImpression =
  trackContentImpressionWithMatomo;

export const trackMediaAnalytics: TrackMediaAnalytics = trackMediaWithMatomo;

export const resetContentImpressionTrackers: ResetContentImpressionTrackers =
  resetContentImpressionTrackersWithMatomo;

export const resetAnalytics: ResetAnalytics = resetAnalyticsWithMatomo;

export const trackSubscriptions: TrackSubscriptions =
  trackSubscriptionsWithMatomo;

export const trackUserPermissions: TrackUserPermissions =
  trackUserPermissionsWithMatomo;

export const trackEvent: TrackEvent = trackEventWithMatomo;
