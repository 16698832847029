import type { Period } from '../../data/period';

export function mapSdkToPeriod(contentfulPeriod: any): Period {
  const {
    fields: { name, slug },
    sys: { id },
  } = contentfulPeriod;

  return {
    id,
    name,
    slug,
  };
}
