import dayjs from 'dayjs';
import fetch from 'isomorphic-unfetch';
import URI from 'urijs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import type { UsatSurveyMetadataDto } from '../../data/usat-survey-metadata';
import type { GetUsatSurveyMetadata } from '../types';

dayjs.extend(isSameOrBefore);

export const getUsatSurveyMetadataFromApi: GetUsatSurveyMetadata = async (
  userId
) => {
  const shouldDisplayBannerUri = new URI(
    `${window.location.origin}/api/user-survey-metadata?user-id=${userId}`
  ).toString();

  const response = await fetch(shouldDisplayBannerUri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    throw new Error(jsonResponse.error.message);
  }

  const { nextDisplayTimestamp, dismissalCounter } =
    (await response.json()) as UsatSurveyMetadataDto;

  return {
    userId,
    nextDisplayTimestamp: dayjs(nextDisplayTimestamp),
    dismissalCounter,
  };
};
