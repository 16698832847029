import { mapSdkToPdfExtract } from './map-to-pdf-extract';
import type { PdfExtract } from '../../../data/pdf-extract';
import type { Content } from '../../../../content/data/content';
import { mapContentfulEntriesToContents } from '../search-api/map-contentful-to-domain-models';
import { getContentfulClient } from '../../../../config/contentful-config';
import type { GetPdfExtractsByIds } from '../../types';
import { getEntriesIsomorphic } from '../../../../respositories/isomorphic/contentful-repository';

export const getPdfExtractsByIdsFromContentful: GetPdfExtractsByIds = async (
  ids: string[]
) => {
  const response = await getEntriesIsomorphic(
    {
      content_type: 'pdfExtract',
      'sys.id[in]': ids.join(),
    },
    getContentfulClient()
  );

  const pdfExtracts = response.items.map(mapSdkToPdfExtract);

  return ids.map((id) =>
    pdfExtracts.find((pdfExtract) => pdfExtract.id === id)
  ) as PdfExtract[];
};

export async function getPdfExtractContent(
  pdfExtractId: string,
  countryCode: string | null,
  blockedProfiles: string[] | null
): Promise<Content | null> {
  const client = getContentfulClient();
  const content = await Promise.allSettled([
    getEntriesIsomorphic(
      {
        content_type: 'guide',
        'fields.relatedPdfExtracts.sys.id[in]': pdfExtractId,
      },
      client,
      {
        countryCode,
        blockedProfiles,
      }
    ),
    getEntriesIsomorphic(
      {
        content_type: 'lessonPlan',
        'fields.relatedPdfExtracts.sys.id[in]': pdfExtractId,
      },
      client,
      {
        countryCode,
        blockedProfiles,
      }
    ),
  ]);

  const results = content.flatMap((r) => {
    if (r.status === 'fulfilled') {
      return r.value.items;
    }
    return [];
  });

  if (results.length === 0) {
    return null;
  }

  const mappedContent = mapContentfulEntriesToContents(results)[0];
  return mappedContent;
}
