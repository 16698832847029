import {
  Action,
  Category,
  TourBeRemindedEvent,
  TourDisabledEvent,
  TourDismissedEvent,
  TourFinishedEvent,
  TourRestartedEvent,
  TourStartedEvent,
  TourStepCompletedEvent,
  TourViewedEvent,
} from '../data/events';

export const createTourViewedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourViewedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.VIEW,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourStartedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourStartedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.START,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourStepCompletedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourStepCompletedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.STEP_COMPLETE,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourFinishedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourFinishedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.EXIT,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourBeRemindedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourBeRemindedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.REMIND,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourDismissedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourDismissedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.DISMISS,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourRestartedEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourRestartedEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.RESTART,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

export const createTourDisabledEvent = (
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): TourDisabledEvent => ({
  category: Category.PRODUCT_TOUR,
  action: Action.DISABLE,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});
