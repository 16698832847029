export const interactiveElementSelector = 'a, button, input';

export function getInteractiveElements(
  parentElement: HTMLElement
): Array<HTMLElement> {
  return [
    ...parentElement.querySelectorAll<HTMLElement>(interactiveElementSelector),
  ];
}

export function setChildInteractivity(
  parentElement: HTMLElement,
  isInteractive: boolean
): void {
  getInteractiveElements(parentElement).forEach((interactiveElement) => {
    if (interactiveElement instanceof HTMLElement) {
      if (isInteractive) {
        interactiveElement.removeAttribute('tabIndex');
      } else {
        interactiveElement.setAttribute('tabIndex', '-1');
      }
    }
  });
}
