import {
  getWorkshopFromContentful,
  newGetWorkshopNameFromContentful,
  newGetWorkshopsForMultipleSlugsFromContentful,
} from './adapters/contentful-workshop-content-api';
import { getContentfulClient } from '../../../config/contentful-config';
import type {
  GetWorkshop,
  GetWorkshopsForMultipleSlugs,
} from './types';
import type { GetContentNameBySlug } from "../../../auth/utils/authentication";

export const getWorkshop: GetWorkshop = getWorkshopFromContentful;

export const getWorkshopName: GetContentNameBySlug =
  newGetWorkshopNameFromContentful(getContentfulClient());

export const getWorkshopsForMultipleSlugs: GetWorkshopsForMultipleSlugs =
  newGetWorkshopsForMultipleSlugsFromContentful(getContentfulClient());
