import { getEntries } from '../../../respositories/frontend/contentful-repository';
import type { TransformSlugsToIds } from '../types';

export const transformSlugsToIdsFromContentful: TransformSlugsToIds = async (
  contentType: string,
  slugs: string[]
) => {
  const query = {
    content_type: contentType,
  };
  query['fields.slug[in]'] = slugs.join();

  const { items } = await getEntries(query);

  return items.map((role) => role.sys.id);
};
