import { gql, isApolloError } from '@apollo/client';
import customerApi from '../../../config/customer-api';

type BookmarkItem = {
  contentId: string;
  addedAt: string;
  relatedContentId: string | null;
};

type AddBookmarkItemResponse = {
  addBookmarkItem: {
    items: Array<BookmarkItem>;
  };
};

const MUTATION = gql`
  mutation AddBookmarkItem($item: DdbPlaylistItemInput!) {
    addBookmarkItem(item: $item) {
      items {
        contentId
        relatedContentId
        addedAt
      }
    }
  }
`;

export async function addBookmark(
  { id, relatedContentId }: { id: string; relatedContentId?: string },
  accessToken: string
): Promise<BookmarkItem | undefined> {
  const client = customerApi.v3.createApolloClient(accessToken);

  try {
    const { data, errors } = await client.mutate<AddBookmarkItemResponse>({
      mutation: MUTATION,
      variables: {
        item: {
          contentId: id,
          relatedContentId,
        },
      },
    });

    if (errors) {
      // eslint-disable-next-line no-console
      console.error(`Unable to remove bookmark: ${JSON.stringify(errors)}`);
      throw new Error('Unable to remove bookmark');
    }

    return data?.addBookmarkItem?.items.find(
      (item) =>
        item.contentId === id &&
        item.relatedContentId === (relatedContentId ?? null)
    );
  } catch (e) {
    if (e instanceof Error && isApolloError(e)) {
      if (e.graphQLErrors.length === 1) {
        const error = e.graphQLErrors[0];
        if (error.message.includes('Item already exists in this playlist')) {
          // The item was already added into the bookmark playlist
          // Just ignoring as we already have the expected state.
          return undefined;
        }
      }
    }

    throw e;
  }
}
