/* eslint-disable comma-dangle */
import createCachedSelector from 're-reselect';
import type { RootState } from '..';
import type {
  Notification,
  NotificationArea,
} from '../../ui/notifications/data/notifications';

export const notificationsSelector = (state: RootState) => state.notifications;

export const notificationAreaSelector = createCachedSelector(
  [
    notificationsSelector,
    (_state: RootState, area: string | undefined) => area,
  ],
  (notifications, area): NotificationArea | undefined => {
    if (area) {
      return notifications[area];
    }

    return undefined;
  }
)((_state, name) => name);

export const notificationSelector = createCachedSelector(
  [
    notificationsSelector,
    (_state: RootState, area: string | undefined) => area,
    (_state: RootState, _area: string | undefined, name: string | undefined) =>
      name,
  ],
  (notifications, area, name): Notification | undefined => {
    if (area) {
      const notificationArea = notifications[area];
      if (name) {
        return notificationArea.notifications[name];
      }

      return undefined;
    }

    return undefined;
  }
)((_state, area, name) => `${area}-${name}`);

export const notificationShowSelector = createCachedSelector(
  [
    notificationsSelector,
    (_state: RootState, area: string | undefined) => area,
    (_state: RootState, _area: string | undefined, name: string | undefined) =>
      name,
  ],
  (notifications, area, name): boolean | undefined => {
    if (area) {
      const notificationArea = notifications[area];
      if (name) {
        const notification = notificationArea.notifications[name];
        if (notification) {
          return notification.state.show;
        }
      }

      return undefined;
    }

    return undefined;
  }
)((_state, area, name) => `${area}-${name}`);
