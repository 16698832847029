import type { Entry } from 'contentful';
import type { Segment } from '../../../data/segment';
import { mapSdkToImage } from '../map-to-image';

export const mapSdkToSegment = (contentfulSegment: Entry<any>): Segment => {
  const {
    sys: { id },
    fields: {
      name,
      type,
      image,
      description,
      startTimestampInSeconds,
      endTimestampInSeconds,
    },
  } = contentfulSegment;

  return {
    name,
    id,
    type,
    description: description ?? null, 
    image: mapSdkToImage(image),
    startTimestampInSeconds,
    endTimestampInSeconds,
  };
};
