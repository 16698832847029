import fetch from 'isomorphic-unfetch';
import URI from 'urijs';
import * as iso from 'iso-3166-1';
import { getCountryCodeFromStore } from '../utils/get-country-code-from-store';
import { getBlockedProfilesFromStore } from '../utils/get-blocked-profiles-from-store';

const getFromBff = async (url: string) => {
  const formattedUrl = URI(url);
  const countryCode = await getCountryCodeFromStore();
  const blockedProfiles = await getBlockedProfilesFromStore();

  if (countryCode) {
    const alpha3CountryCode = iso.whereAlpha2(countryCode)?.alpha3;
    formattedUrl.addQuery('country', alpha3CountryCode);
  }

  if (blockedProfiles) {
    formattedUrl.addQuery('blockedProfiles', blockedProfiles);
  }

  return fetch(formattedUrl.toString());
};

export default getFromBff;
