import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export function createApolloClient(
  accessToken: string,
  dtApi?: string,
  apiUrl?: string
): ApolloClient<NormalizedCacheObject> {
  if (apiUrl === undefined) {
    throw Error('you need to supply a graphql api url');
  }

  const httpLink = createHttpLink({
    uri: apiUrl,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'dt-system': 'site',
        'dt-api': dtApi,
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });
}
