import type { ContentfulClientApi } from 'contentful';
import type { Interview } from '../../../interviews/data/interview';
import type { Workshop } from '../../../workshops/data/workshop';
import { getEntries } from '../../../respositories/frontend/contentful-repository';
import { mapSdkToWorkshop } from '../../../workshops/api/frontend/adapters/mappers/map-sdk-to-workshop';
import { mapSdkToInterview } from '../../../interviews/api/frontend/adapters/mappers/map-sdk-to-interview';
import { CONTENTFUL_CONTENT_TYPE } from './types';
import toContentfulOrdering from '../../../utils/to-contentful-ordering';

export function newGetInterviewsAndWorkshopsForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: string[]): Promise<(Interview | Workshop)[]> => {
    const workshopResponse = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.WORKSHOP,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const interviewResponse = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.INTERVIEW,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const mappedInterviews = interviewResponse.items.map(mapSdkToInterview);
    const mappedWorkshops = workshopResponse.items.map(mapSdkToWorkshop);

    const mappedInterviewsAndWorkshops = [
      ...mappedInterviews,
      ...mappedWorkshops,
    ];

    const orderedInterviewsAndWorkshops = toContentfulOrdering(
      slugs,
      mappedInterviewsAndWorkshops
    );

    return orderedInterviewsAndWorkshops;
  };
}
