import { createContext, useMemo, useEffect, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useRouter } from 'next/router';
import type { AuthState } from '@okta/okta-auth-js';
import AppAuthService from '../services/auth-service';
import type { IpAuthOrganisation } from '../data/ip-auth';
import { getIPOrganisation, getOrganisation } from '../api/frontend';
import {
  trackEvent,
  trackMasterAccount,
  trackOrganisation,
  trackSubscriptions,
  trackUser,
  trackUserPermissions,
} from '../../analytics/api';
import { useAppDispatch } from '../../store/hooks/use-app-dispatch';
import {
  setBookmarks,
  setInfo,
  setIsInitialised,
  setOrganisation,
} from '../../store/user/user.slice';

import { getBookmarksWithContent } from '../../bookmarks/api/frontend/get-bookmark-items';
import type { GetOrganisationResponse } from '../api/frontend/types';
import { createEducationNavigationViewEvent } from '../../analytics/events/create-education-navigation-view-event';
import { Action } from '../../analytics/data/events';
import { useAnalyticsContext } from '../../analytics/providers/analytics-context';
import { getEducationLevel } from '../utils/get-education-level';

export interface AuthContextProps {
  appAuthService: AppAuthService | null;
  appAuthState: AuthState | null;
}

export const AuthContext = createContext<AuthContextProps>({
  appAuthService: null,
  appAuthState: null,
});

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);

interface Props {
  children: React.ReactNode;
  appAuthService?: AppAuthService;
  ipAuth: IpAuthOrganisation | null;
}

const ipAuthState = {
  accessToken: undefined,
  idToken: undefined,
  isAuthenticated: true,
};

const AuthContextProvider: React.FC<Props> = ({
  children,
  appAuthService,
  ipAuth,
}: Props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const router = useRouter();
  const { pageTitle, pageType } = useAnalyticsContext();

  const authInstance = useMemo(() => {
    return appAuthService || new AppAuthService(oktaAuth, router);
  }, [appAuthService, oktaAuth, router]);

  const dispatch = useAppDispatch();

  const getAndTrackOrganisationData = async (
    id: string,
    orgType = 'standard'
  ) => {
    let result: GetOrganisationResponse;
    if (orgType === 'ipAuth') {
      result = await getIPOrganisation(id);
    } else {
      result = await getOrganisation(id);
    }

    const { masterAccount, organisation, subscriptions } = result;

    if (masterAccount) {
      trackMasterAccount(masterAccount);
    }

    if (organisation) {
      trackOrganisation(organisation);
    }

    if (subscriptions) {
      trackSubscriptions(subscriptions);
    }

    // IPAuth never attaches permissions, so don't pass them in
    if (orgType === 'ipAuth') {
      trackUserPermissions();
    } else {
      trackUserPermissions(result as any);

      try {
        const bookmarks = await getBookmarksWithContent(
          organisation.country_alpha3,
          organisation.blockedProfiles
        );

        dispatch(setBookmarks(bookmarks));
      } catch (e) {
        // Ignore for now.
      }
    }

    dispatch(setOrganisation(result));

    // Send the education navigation view event
    const createAndTrackEducationNavigationViewEvent = (eventName) => {
      trackEvent(
        createEducationNavigationViewEvent(
          Action.EDUCATION_NAVIGATION_VIEW,
          eventName,
          pageType,
          pageTitle,
          router.asPath
        )
      );
    };
    const educationLevel = getEducationLevel(result.organisation.category);
    const isUS = result.organisation.country === 'US';
    const isUK = result.organisation.country === 'GB';
    const isMiddleOrSecondary =
      educationLevel === 'Middle School' || educationLevel === 'Secondary';
    if (isUS && isMiddleOrSecondary) {
      createAndTrackEducationNavigationViewEvent('US Secondary and Middle');
      createAndTrackEducationNavigationViewEvent('US Higher Education');
    }
    if (isUS && educationLevel === 'Higher Education') {
      createAndTrackEducationNavigationViewEvent('US Higher Education');
    }
    if (isUK && educationLevel === 'Higher Education') {
      createAndTrackEducationNavigationViewEvent('UK Higher Education');
    }
  };

  useEffect(() => {
    if (authState !== null) {
      if (authState.isAuthenticated) {
        localStorage.setItem('loginTries', '0');
        authInstance!.getUserInfo().then(async (userInfo) => {
          if (userInfo) {
            dispatch(setInfo(userInfo));
          }
          if (userInfo?.sub) {
            trackUser(userInfo.sub);

            getAndTrackOrganisationData(userInfo.sub);
          }
          dispatch(setIsInitialised(true));
        });
      } else if (ipAuth) {
        getAndTrackOrganisationData(ipAuth.organisationId, 'ipAuth');
        dispatch(setInfo({ sub: '', name: ipAuth.organisationName }));
        dispatch(setIsInitialised(true));
      } else {
        dispatch(setIsInitialised(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated, authState]);

  const contextValue = useMemo(() => {
    return {
      appAuthService: authInstance,
      appAuthState: ipAuth ? ipAuthState : authState,
    };
  }, [authInstance, authState, ipAuth]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
