import type { RelatedContentSlugs } from '../../data/related-content-slugs';

export const mapToRelatedContentSlugs = (
  relatedContent: any
): RelatedContentSlugs => {
  const relatedInterviewSlugs: string[] = [];
  const relatedWorkshopSlugs: string[] = [];
  const relatedGuideSlugs: string[] = [];
  const relatedProductionSlugs: string[] = [];
  const relatedLessonPlanSlugs: string[] = [];
  const relatedMultipleSeriesSlugs: string[] = [];

  if (relatedContent) {
    relatedContent.forEach(({ sys, fields }: any) => {
      if (sys.contentType.sys.id === 'interview') {
        relatedInterviewSlugs.push(fields.slug);
      }
      if (sys.contentType.sys.id === 'workshop') {
        relatedWorkshopSlugs.push(fields.slug);
      }
      if (sys.contentType.sys.id === 'guide') {
        relatedGuideSlugs.push(fields.slug);
      }
      if (sys.contentType.sys.id === 'lessonPlan') {
        relatedLessonPlanSlugs.push(fields.slug);
      }
      if (sys.contentType.sys.id === 'production') {
        relatedProductionSlugs.push(fields.slug);
      }
      if (sys.contentType.sys.id === 'series') {
        relatedMultipleSeriesSlugs.push(fields.slug);
      }
    });
  }

  return {
    relatedGuideSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedProductionSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
  };
};
