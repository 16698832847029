import type { EducationLevelName } from '../data/education-level-name';
import type { Organisation } from '../data/user';

function keysToObjectWithValue(value) {
  return (result: object, item: string) => ({ ...result, [item]: value });
}

const secondary = [
  'Charter Management Organization',
  'Charter School',
  'Combined',
  'Dance School',
  'District',
  'Drama School',
  'HomeSchool',
  'International School',
  'Primary/Secondary',
  'School Group',
  'Secondary School',
  'Trust',
].reduce(keysToObjectWithValue('Secondary'), {});

const middle = ['Middle School'].reduce(
  keysToObjectWithValue('Middle School'),
  {}
);

const higher = [
  'Further Education',
  'Higher Education',
  'Library',
  'US - 2 Year College',
  'US - 4 Year College',
].reduce(keysToObjectWithValue('Higher Education'), {});

const other = [
  'Care Home',
  'Consortium',
  'Council',
  'Other',
  'Owner',
  'Partner',
  'Partnerships',
  'Pre-School',
  'Primary/Elementary School',
  'Special',
  'US',
  'Vendor',
].reduce(keysToObjectWithValue('Other'), {});

const educationLevelMap = {
  ...secondary,
  ...middle,
  ...higher,
  ...other,
};

export const getEducationLevel = (
  orgCategory: Organisation['category'] | null
): EducationLevelName => {
  if (orgCategory === null) {
    return 'Unknown';
  }

  const educationLevel = educationLevelMap[orgCategory];
  if (!educationLevel) {
    return 'Unknown';
  }

  return educationLevel;
};
