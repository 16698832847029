import type { ContentfulClientApi } from 'contentful';
import type { GetWorkshop } from '../types';
import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';
import { mapSdkToWorkshop } from './mappers/map-sdk-to-workshop';
import type { Workshop } from '../../../data/workshop';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';
import { getContentfulClient } from '../../../../config/contentful-config';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export function newGetWorkshopsForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: string[]): Promise<Workshop[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.WORKSHOP,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const workshops = response.items;

    return workshops.map(mapSdkToWorkshop);
  };
}

export const getWorkshopFromContentful: GetWorkshop = async (slug) => {
  const workshops = await newGetWorkshopsForMultipleSlugsFromContentful(
    getContentfulClient()
  )([slug]);

  if (workshops.length === 0) {
    throw Error('Invalid or missing slug');
  }

  return workshops[0];
};

export const newGetWorkshopNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );

    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.WORKSHOP,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};
