/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_SEARCH_STATE } from './search.state';

export const searchSlice = createSlice({
  name: 'search',
  initialState: INITIAL_SEARCH_STATE,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSingleSearchQuery: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      if (
        !state.searchQuery[action.payload.key].includes(action.payload.value)
      ) {
        state.searchQuery[action.payload.key].push(action.payload.value);
      }
    },
    removeSingleSearchQuery: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      state.searchQuery[action.payload.key] = state.searchQuery[
        action.payload.key
      ].filter((query) => query !== action.payload.value);
    },
    setSearchQuery: (
      state,
      action: PayloadAction<{ types: string[]; subTypes: string[] }>
    ) => {
      state.searchQuery.types = action.payload.types;
      state.searchQuery.subTypes = action.payload.subTypes;
    },
    removeAllSearchQueries: (state) => {
      state.searchQuery.types = [];
      state.searchQuery.subTypes = [];
    },
  },
});

export const {
  setSearchTerm,
  setSingleSearchQuery,
  removeSingleSearchQuery,
  setSearchQuery,
  removeAllSearchQueries,
} = searchSlice.actions;

export default searchSlice.reducer;
