/* eslint-disable no-param-reassign */
/**
 * range
 *
 * Based on Python's range function, from
 * https://stackoverflow.com/questions/8273047/javascript-function-similar-to-python-range
 *
 * e.g. [...range(0,5)] = [0, 1, 2, 3, 4]
 * [...range(0, 8, 2)] = [0, 2, 4, 6]
 *
 * Function is a generator, so you can use in for... of loops
 *
 * @param start start value
 * @param stop end value (by convention in Python the last value is one step below end)
 * @param step the increment of each value
 */
function* range(
  start: number,
  stop?: number | undefined,
  step = 1
): IterableIterator<number> {
  if (typeof stop === 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    yield i;
  }
}

export default range;
