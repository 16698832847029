import type { Series } from '../../../../data/series';
import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToSeriesRelatedContent } from './map-to-series-related-content';

export const mapSdkToSeries = (series: any): Series => {
  const {
    sys: { id, createdAt },
    fields: {
      name,
      slug,
      shortIntroduction,
      longIntroduction,
      mainImage,
      relatedItems,
      relatedEducationLevels,
    },
  } = series;

  return {
    id,
    name,
    slug,
    firstPublishedAt: createdAt ? createdAt.substring(0, 10) : null,
    image: mapSdkToImage(mainImage),
    shortIntroduction: shortIntroduction ?? null,
    longIntroduction: longIntroduction ?? null,
    relatedContent: mapToSeriesRelatedContent(relatedItems),
    relatedEducationLevelNames:
      relatedEducationLevels?.map(
        (educationLevel) => educationLevel.fields.name
      ) ?? [],
  };
};
