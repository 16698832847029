import type { SeriesRelatedContent } from '../../../../../shared/data/series-related-content';

export const mapToSeriesRelatedContent = (
  relatedItems
): SeriesRelatedContent[] => {
  return relatedItems.map((item) => ({
    type: item.sys.contentType.sys.id,
    slug: item.fields.slug,
    id: item.sys.id,
  }));
};
