import { waitUntilStoreInitialised } from './wait-until-store-is-initialised';
import store from '../store';

export const getBlockedProfilesFromStore = async (): Promise<
  string[] | null
> => {
  if (typeof window !== 'undefined') {
    await waitUntilStoreInitialised();
  }

  const state = store.getState();
  if (!state.user.name) {
    return null;
  }

  const { blockedProfiles } = state.user.organisation;

  if (blockedProfiles) {
    return blockedProfiles;
  }

  return new Promise((res) => {
    store.subscribe(() => {
      const orgBlockedProfiles = state.user.organisation.blockedProfiles;
      if (orgBlockedProfiles) {
        res(orgBlockedProfiles);
      }
    });
  });
};
