import type { ContentfulClientApi } from 'contentful';

import type { Interview } from '../../../data/interview';

import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import { mapSdkToInterview } from './mappers/map-sdk-to-interview';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import type { GetInterview, GetInterviewsForMultipleSlugs } from '../types';
import { getContentfulClient } from '../../../../config/contentful-config';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export const newGetInterviewsForMultipleSlugs = (
  client: ContentfulClientApi
): GetInterviewsForMultipleSlugs => {
  return async (slugs: string[]): Promise<Interview[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.INTERVIEW,
        'fields.slug[in]': slugs.join(),
      },
      client
    );

    return response.items.map(mapSdkToInterview);
  };
};

export const getInterviewsForMultipleSlugsFromContentful: GetInterviewsForMultipleSlugs =
  newGetInterviewsForMultipleSlugs(getContentfulClient());

export const getInterviewFromContentful: GetInterview = async (slug) => {
  const interviews = await getInterviewsForMultipleSlugsFromContentful([slug]);

  if (interviews.length === 0) {
    throw Error('Invalid or missing slug');
  }

  return interviews[0];
};

export const newGetInterviewNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );

    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.INTERVIEW,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};
