/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */

import App from 'next/app';
import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { resetId } from 'react-id-generator';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import requestIP from 'request-ip';

import '../styles/tailwind.scss';
import '../styles/global.scss';
import '../styles/tailwind-utilities.scss';
import { ThemeProvider } from 'styled-components';
import { useRouter } from 'next/router';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaConfig } from '../config/okta-config';
import AuthContextProvider from '../auth/providers/auth-context';
import type { IpAuthOrganisation } from '../auth/data/ip-auth';
import { setupTracker } from '../analytics/api';
import { queryClient } from '../cache';
import store from '../store';
import { getOrganisationWithIp } from '../auth/api/isomorphic';
import { ConsoleLogger } from '../shared/services/logging/console-logger';
import { MediaBreakpointProvider } from '../shared/contexts/media-breakpoints/media-breakpoint-provider';
import { SCTheme } from '../styles/styled-theme/theme';
import BottomNotificationArea from '../shared/components/notifications/bottom-notification-area';
import { TourProvider } from '../shared/contexts/tour/tour-provider';

const logger = new ConsoleLogger();
const Security = dynamic(
  () => import('@okta/okta-react').then((mod) => mod.Security) as any,
  { ssr: false }
) as any;
const BrowserRouter = dynamic(
  () => import('react-router-dom').then((mod) => mod.BrowserRouter) as any,
  { ssr: false }
) as any;

type extendedAppProps = AppProps & {
  ipAuth: IpAuthOrganisation | null;
};

export const MyApp = ({
  Component,
  pageProps,
  ipAuth: initialIpAuth,
}: extendedAppProps): JSX.Element => {
  const [ipAuth] = useState(initialIpAuth);

  const setScrollTopClassName = () =>
    document.documentElement.classList.toggle('scrolled', window.scrollY !== 0);

  useEffect(() => {
    setupTracker();
    window.addEventListener('scroll', setScrollTopClassName);

    return () => {
      window.removeEventListener('scroll', setScrollTopClassName);
    };
  }, []);

  resetId();

  const router = useRouter();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    await router.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
        <Provider store={store}>
          <AuthContextProvider ipAuth={ipAuth}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={SCTheme}>
                <MediaBreakpointProvider>
                  <TourProvider>
                    <Component {...pageProps} />
                    <BottomNotificationArea />
                  </TourProvider>
                </MediaBreakpointProvider>
              </ThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AuthContextProvider>
        </Provider>
      </Security>
    </BrowserRouter>
  );
};

export const initialProps = async (appContext) => {
  let ipAuthedOrganisation: IpAuthOrganisation | null = null;
  try {
    if (typeof window === 'undefined') {
      const ipAddress = requestIP.getClientIp(appContext.ctx.req);
      if (ipAddress) {
        ipAuthedOrganisation = await getOrganisationWithIp(ipAddress);
      }
    }
  } catch (error) {
    ipAuthedOrganisation = {
      organisationId: '40f30c06-261f-42eb-9e6a-624d81adc24d',
      organisationName: 'Default Digital Theatre Account',
    };
    logger.error(
      '[TEMP-DEBUG] App initialProps has thrown error, defaulting organisation',
      error
    );
  }

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps, ipAuth: ipAuthedOrganisation };
};

MyApp.getInitialProps = initialProps;

export default MyApp;
