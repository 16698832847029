import { getContentfulClient } from '../../../config/contentful-config';
import getContentFromBff from '../../../content/factories/get-content-from-bff';
import type { Production } from '../../data/production';
import { newGetNumberOfProductionsAssociatedWithContent } from './adapters/contentful-get-productions-with-content';
import {
  getProductionFromContentful,
  newGetProductionNameFromContentful,
  newGetProductionsForMultipleSlugsFromContentful,
  newGetProductionsForTitle,
  newGetTotalNumberOfProductions,
} from './adapters/contentful-production-content-api';
import type {
  GetNumberOfProductionsAssociatedWithContent,
  GetProduction,
  GetProductions,
  GetProductionsForMultipleSlugs,
  GetTotalNumberOfProductions,
} from './types';
import type { GetContentNameBySlug } from "../../../auth/utils/authentication";

export const getProduction: GetProduction = getProductionFromContentful;

export const getProductionName: GetContentNameBySlug =
  newGetProductionNameFromContentful(getContentfulClient());

export const getProductions: GetProductions =
  getContentFromBff<Production>('productions');

export const getNumberOfProductionsAssociatedWithContent: GetNumberOfProductionsAssociatedWithContent =
  newGetNumberOfProductionsAssociatedWithContent(getContentfulClient());

export const getTotalNumberOfProductions: GetTotalNumberOfProductions =
  newGetTotalNumberOfProductions(getContentfulClient());

export const getProductionsForMultipleSlugs: GetProductionsForMultipleSlugs =
  newGetProductionsForMultipleSlugsFromContentful(getContentfulClient());

export const getProductionsForTitle = newGetProductionsForTitle(
  getContentfulClient()
);
