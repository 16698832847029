import { ContentType } from '../../../../../../content/data/content-type';
import { mapSdkToImage } from '../../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../../shared/api/adapters/map-to-related-content-slugs';
import type { Person } from '../../../../../data/person';

export const mapSdkResponseToPerson = (contentfulPerson: any): Person => {
  const {
    fields: {
      name,
      slug,
      description,
      mainImage,
      relatedRoles,
      relatedContent,
      relatedEducationLevels,
      curated,
    },
    sys: { id, createdAt },
  } = contentfulPerson;

  const {
    relatedGuideSlugs,
    relatedInterviewSlugs,
    relatedLessonPlanSlugs,
    relatedProductionSlugs,
    relatedWorkshopSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    id,
    name,
    slug,
    type: ContentType.PERSON,
    description: description ?? null,
    relatedRoles: relatedRoles
      ? relatedRoles.map((role) => role.fields.name)
      : [],
    firstPublishedAt: createdAt ? createdAt.substring(0, 10) : null,
    image: mapSdkToImage(mainImage),
    relatedGuideSlugs,
    relatedInterviewSlugs,
    relatedLessonPlanSlugs,
    relatedProductionSlugs,
    relatedWorkshopSlugs,
    relatedMultipleSeriesSlugs,
    relatedEducationLevelNames:
      relatedEducationLevels?.map(
        (educationLevel) => educationLevel.fields.name
      ) ?? [],
    curated,
  };
};
