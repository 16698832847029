import { gql } from '@apollo/client';
import fetch from 'isomorphic-unfetch';
import URI from 'urijs';
import customerApi from '../../../config/customer-api';

const QUERY = gql`
  query GetBookmarks {
    getBookmarks {
      items {
        contentId
        relatedContentId
        addedAt
      }
    }
  }
`;

export interface BookmarkItem {
  contentId: string;
  relatedContentId: string | null;
  addedAt: string;
}

interface GetBookmarks {
  getBookmarks: {
    items: BookmarkItem[];
  };
}

export async function getBookmarksWithContent(
  country: string,
  blockedProfiles: string[]
) {
  const uri = new URI('/api/bookmarks')
    .addQuery('country', country)
    .addQuery(
      'blockedProfiles',
      blockedProfiles.length === 0 ? '' : blockedProfiles
    );
  const response = await fetch(uri.toString());
  return response.json();
}

export async function getBookmarkItems({
  accessToken,
}): Promise<BookmarkItem[]> {
  const client = customerApi.v3.createApolloClient(accessToken);

  const { data, error } = await client.query<GetBookmarks>({
    query: QUERY,
  });

  if (error) {
    // eslint-disable-next-line no-console
    console.log('Unable to get Bookmark playlist data', JSON.stringify(error));
    throw new Error('Unable to get Bookmark playlist data');
  }

  if (data.getBookmarks === null) {
    return [];
  }

  const {
    getBookmarks: { items },
  } = data;

  const sortedItems = [...items].sort((a, b) => {
    const dateA = new Date(a.addedAt);
    const dateB = new Date(b.addedAt);
    return dateB.getTime() - dateA.getTime();
  });

  return sortedItems;
}
