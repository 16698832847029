import createCachedSelector from 're-reselect';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import type { RootState } from '../../store';
import { subscriptionsSelector } from '../../store/user/selectors';
import {
  Subscription,
  SubscriptionsStatus,
  SubscriptionState,
} from '../data/user';

dayjs.extend(isBetween);

export const getSubscriptionState = (
  subscriptionsStatus: SubscriptionsStatus
): SubscriptionState => {
  const {
    hasActiveFullSubscription,
    hasActiveTrialSubscription,
    hasExpiredSubscription,
    hasSubscriptionThatWillStartInFuture,
  } = subscriptionsStatus;

  if (hasActiveFullSubscription) {
    return SubscriptionState.FULL;
  }
  if (hasActiveTrialSubscription) {
    return SubscriptionState.TRIAL;
  }
  if (hasSubscriptionThatWillStartInFuture) {
    return SubscriptionState.FUTURE;
  }
  if (hasExpiredSubscription) {
    return SubscriptionState.EXPIRED;
  }
  return SubscriptionState.NONE;
};

export const earliestFutureSubscription = (
  subscriptions: Subscription[]
): Subscription | null => {
  if (subscriptions.length === 0) {
    return null;
  }
  return subscriptions
    .filter((subscription) => dayjs().isBefore(subscription.startDate))
    .reduce((prev, curr) => {
      return prev.startDate < curr.startDate ? prev : curr;
    });
};

export const latestSubscription = (
  subscriptions: Subscription[]
): Subscription | null => {
  if (!subscriptions || subscriptions.length === 0) {
    return null;
  }

  return subscriptions.reduce(
    (prev: Subscription | null, curr: Subscription): Subscription => {
      return prev && prev.endDate > curr.endDate ? prev : curr;
    },
    null
  );
};

export const lastSubscriptionEndDate = (subscriptions) => {
  const lastSubscription = latestSubscription(subscriptions);

  return dayjs(lastSubscription?.endDate);
};

export const getAllSubscriptionsByType = (
  subscriptions: Subscription[],
  type: 'full' | 'trial'
): Subscription[] => {
  return subscriptions
    ? subscriptions.filter((subscription) => subscription.type === type)
    : [];
};

export const getActiveSubscriptionsByType = (
  subscriptions: Subscription[],
  type: 'full' | 'trial'
): Subscription[] => {
  return getAllSubscriptionsByType(subscriptions, type).filter((subscription) =>
    dayjs().isBetween(subscription.startDate, subscription.endDate, null, '[]')
  );
};

export const latestSubscriptionByTypeSelector = createCachedSelector(
  [subscriptionsSelector, (_state: RootState, type: 'full' | 'trial') => type],
  (subscriptions, type) => {
    return latestSubscription(
      getActiveSubscriptionsByType(subscriptions, type)
    );
  }
)((_state, type) => type);
