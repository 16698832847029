import { createContext } from 'react';
import { MediaBreakpoints } from './types/media-breakpoints';

export const MediaBreakpointContext = createContext<{
  mediaBreakpoint: MediaBreakpoints;
  windowWidth: number;
  windowHeight: number;
}>({
  mediaBreakpoint: MediaBreakpoints.UNKNOWN,
  windowWidth: 0,
  windowHeight: 0,
});
