import type { ContentfulClientApi } from 'contentful';

import type { Production } from '../../../data/production';

import type { GetProduction } from '../types';
import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import { mapSdkToProduction } from './mappers/map-sdk-to-production';
import toContentfulOrdering from '../../../../utils/to-contentful-ordering';
import { getContentfulClient } from '../../../../config/contentful-config';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export function newGetProductionsForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: any): Promise<Production[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.PRODUCTION,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const { items } = response;

    const mappedProductions = items.map(mapSdkToProduction);

    const orderedProductions = toContentfulOrdering(slugs, mappedProductions);

    return orderedProductions;
  };
}

export const getProductionFromContentful: GetProduction = async (slug) => {
  const productions = await newGetProductionsForMultipleSlugsFromContentful(
    getContentfulClient()
  )([slug]);

  if (productions.length === 0) {
    throw Error('Invalid or missing slug');
  }

  return productions[0];
};

export const newGetProductionNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );

    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.PRODUCTION,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};

export function newGetTotalNumberOfProductions(client: ContentfulClientApi) {
  return async (): Promise<number> => {
    const { total } = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.PRODUCTION,
        limit: 0,
      },
      client
    );

    return total;
  };
}

export function newGetProductionsForTitle(
  contentfulClient: ContentfulClientApi
) {
  return async function getProductionsForTitle(
    titleSlug: string
  ): Promise<Production[]> {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.PRODUCTION,
        'fields.relatedTitle.sys.contentType.sys.id':
          CONTENTFUL_CONTENT_TYPE.TITLE,
        'fields.relatedTitle.fields.slug': titleSlug,
      },
      contentfulClient
    );

    const { items } = response;

    return items.map(mapSdkToProduction).sort((a, b) =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    );
  };
}
