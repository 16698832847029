export enum Category {
  CARD = 'card',
  CARD_EXTRACT = 'card-extract',
  CONTENT_RATING = 'content-rating',
  PDF = 'pdf',
  BROWSE = 'browse',
  NAVIGATION = 'navigation',
  EDUCATION_NAVIGATION = 'education-navigation',
  RESOURCE_NAVIGATION = 'resource-navigation',
  LINK = 'link',
  FILTER = 'filter',
  SORT = 'sort',
  RESULTS = 'results',
  PAGINATE = 'paginate',
  SECTIONS = 'sections',
  LIST_PANEL = 'list-panel',
  SEARCH = 'search',
  CLASSIFICATION = 'classification',
  SHARE = 'share',
  BOOKMARKING = 'bookmarking',
  STUDY_UNIT = 'study-unit',
  VIEW = 'view',
  PRODUCT_TOUR = 'product-tour',
  AUTHENTICATION = 'authentication',
  LOGIN_RETRY_COUNT = 'login-retry-count',
}

export enum Action {
  DISABLE = 'disable',
  DISMISS = 'dismiss',
  RESTART = 'restart',
  REMIND = 'remind',
  EXIT = 'exit',
  STEP_COMPLETE = 'step-complete',
  UNKNOWN = 'unknown',
  SEE_ALL = 'see-all',
  PDF_PRINT = 'print',
  PDF_DOWNLOAD = 'download',
  PDF_SEARCH = 'search',
  PDF_SCROLL_DEPTH = 'scroll-depth',
  FILTER_EXPAND = 'expand',
  FILTER_COLLAPSE = 'collapse',
  FILTER_SELECT = 'select',
  FILTER_REMOVE = 'remove',
  FILTER_SELECT_ALL = 'select-all',
  FILTER_CLEAR_ALL = 'clear-all',
  FILTER_PILL_REMOVE = 'remove-filter',
  CLEAR_ALL_FILTERS = 'clear-all-filters',
  HEADER = 'header',
  FOOTER = 'footer',
  BANNER = 'banner',
  TAB_NAVIGATION = 'tab-navigation',
  DROPDOWN = 'dropdown',
  SELECT = 'select',
  JUMP_TO = 'jump-to',
  START_SEARCH = 'start-search',
  SUBMIT = 'submit',
  CLEAR = 'clear',
  SKILL = 'skill',
  GENRE = 'genre',
  THEME = 'theme',
  COURSE = 'course',
  OPEN = 'open',
  CLOSE = 'close',
  COPY = 'copy',
  SET_START = 'set-start',
  SET_END = 'set-end',
  ADD = 'add',
  REMOVE = 'remove',
  UNDO_REMOVE = 'undo-remove',
  LINK = 'link',
  EDUCATION_NAVIGATION_VIEW = 'education-navigation-view',
  EDUCATION_NAVIGATION_BROWSE = 'education-navigation-browse',
  MIDDLE_SCHOOL = 'middle-school',
  HIGH_SCHOOL = 'high-school',
  KEY_STAGE_3 = 'key-stage-3',
  GCSE = 'gcse',
  HIGHER_EDUCATION_US = 'higher-education-us',
  HIGHER_EDUCATION_UK = 'higher-education-uk',
  BROWSE = 'browse',
  VIEW = 'view',
  START = 'start',
  BACK = 'back',
  RATE = 'rate',
  SKIP = 'skip',
}

export enum Name {
  PRINT_ICON = 'print-icon',
  DOWNLOAD_ICON = 'download-icon',
  READ_25 = '25%',
  READ_50 = '50%',
  READ_75 = '75%',
  READ_100 = '100%',
}

export enum ClickedFromPageTitle {
  UNKNOWN = 'unknown',
  HOMEPAGE = 'Digital Theatre+',
  PRODUCTIONS = 'productions',
  PEOPLE = 'people',
  GUIDES = 'guides',
  VIDEO_RESOURCES = 'video resources',
  LESSON_PLANS = 'lesson plans',
  TITLES = 'titles',
  SERIES = 'series',
  GENRES = 'genres',
  THEMES = 'themes',
  STUDY_UNITS = 'study units',
  SEARCH_RESULTS = 'Search:',
}

export enum ClickedFromPageType {
  UNKNOWN = 'unknown',
  HOMEPAGE = 'home',
  DIRECTORY = 'directory',
  SEARCH_RESULTS = 'search results',
  TABLE_OF_CONTENTS = 'table of contents',
  PRODUCTION = 'production',
  GENRE = 'genre',
  GUIDE = 'guide',
  WORKSHOP = 'workshop',
  INTERVIEW = 'interview',
  LESSON_PLAN = 'lesson-plan',
  PERSON = 'person',
  TITLE = 'title',
  THEME = 'theme',
  SERIES = 'series',
  STUDY_UNIT = 'study unit',
}

export enum ClickedFromLocation {
  OVERVIEW = 'overview',
  UNKNOWN = 'unknown',
  NEW_ON_DT = 'new-on-dt',
  FEATURED_BOOKMARKS = 'featured-bookmarks',
  FEATURED_TITLES = 'featured-titles',
  FEATURED_PEOPLE = 'featured-people',
  FEATURED_GENRES_AND_THEMES = 'featured-genres-and-themes',
  FEATURED_SERIES = 'featured-series',
  RELATED_PRODUCTIONS = 'related-productions',
  RELATED_VIDEO_RESOURCES = 'related-video-resources',
  RELATED_WRITTEN_RESOURCES = 'related-written-resources',
  RELATED_TEACHING_RESOURCES = 'related-teaching-resources',
  RELATED_SERIES = 'related-series',
  SERIES_LIST = 'playlist',
  SEARCH = 'search-results',
  CARD_GRID = 'card-grid',
  COLLECTION_DETAIL = 'collection-detail',
  SEGMENT_CONTAINER = 'segment-card-container',
  CLASSIFICATION_COMPONENT = 'classification-component',
  RESOURCE_BAR = 'resource-bar',
  BOOKMARKS_LIST = 'bookmarks-list',
  VERTICAL_FILTERS = 'vertical-filters',
  OTHER_SERIES = 'other-series',
  SECTION = 'section',
  STUDY_UNIT = 'study-unit',
}

export type DynamicClickedFromLocation = ClickedFromLocation | string;

export type ActionDimensions = {
  contentType?: string;
  contentSubtype?: string;
  cardTitle?: string;
  contentId?: string;
  attributedTo?: string;
  cardIndex?: string;
  duration?: string;
  clickedFromPageType?: string;
  clickedFromPageTitle?: string;
  clickedFromLocation?: string;
  clickedFromUrl?: string;
  durationMinutes?: number | null;
};

export type CardClicked = {
  category: Category.CARD;
  action: string;
  name: string;
  value: number;
  contentType: string;
  cardTitle: string;
  contentId: string;
  cardIndex: string;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromLocation: DynamicClickedFromLocation;
  clickedFromUrl: string;
  attributedTo?: string;
  durationMinutes?: number | null;
};

export type SeeAllButtonClicked = {
  category: Category.BROWSE;
  action: Action.SEE_ALL;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type CheckboxClicked = {
  category: Category.FILTER;
  action: Action.FILTER_SELECT | Action.FILTER_REMOVE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type FilterPillRemoved = {
  category: Category.FILTER;
  action: Action.FILTER_PILL_REMOVE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type ClearAllFiltersClicked = {
  category: Category.FILTER;
  action: Action.CLEAR_ALL_FILTERS;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type AccordionClicked = {
  category: Category.FILTER;
  action: Action.FILTER_COLLAPSE | Action.FILTER_EXPAND;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type FilterSelectClicked = {
  category: Category.FILTER;
  action: Action.FILTER_COLLAPSE | Action.FILTER_EXPAND;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type FilterSelectAll = {
  category: Category.FILTER;
  action: Action.FILTER_SELECT_ALL | Action.FILTER_CLEAR_ALL;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type CustomSelectClicked = {
  category: Category.SECTIONS | Category.SORT | Category.RESULTS;
  action: Action.SELECT | string;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type PaginationClicked = {
  category: Category.PAGINATE;
  action: string;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type NavigationClicked = {
  category: Category.NAVIGATION;
  action: Action.HEADER | Action.FOOTER | Action.BANNER;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourViewedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.VIEW;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourStartedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.START;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourStepCompletedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.STEP_COMPLETE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourFinishedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.EXIT;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourBeRemindedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.REMIND;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourDismissedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.DISMISS;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourRestartedEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.RESTART;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TourDisabledEvent = {
  category: Category.PRODUCT_TOUR;
  action: Action.DISABLE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type ContentRatingViewed = {
  category: Category.CONTENT_RATING;
  action: Action.VIEW;
  name: string;
  value: 1;
  viewedFromPageType: string;
  viewedFromPageTitle: string;
  viewedFromUrl: string;
};

export type ContentRatingRated = {
  category: Category.CONTENT_RATING;
  action: Action.RATE;
  name: string;
  value: number;
  viewedFromPageType: string;
  viewedFromPageTitle: string;
  viewedFromUrl: string;
};

export type ContentRatingSubmit = {
  category: Category.CONTENT_RATING;
  action: Action.SUBMIT;
  name: string;
  value: 1;
  viewedFromPageType: string;
  viewedFromPageTitle: string;
  viewedFromUrl: string;
};

export type ContentRatingSkip = {
  category: Category.CONTENT_RATING;
  action: Action.SKIP;
  name: string;
  value: 1;
  viewedFromPageType: string;
  viewedFromPageTitle: string;
  viewedFromUrl: string;
};

export type EducationNavigationViewed = {
  category: Category.VIEW;
  action: Action.EDUCATION_NAVIGATION_VIEW;
  name: string;
  value: 1;
  viewedFromPageType: string;
  viewedFromPageTitle: string;
  viewedFromUrl: string;
};

export type EducationNavigationClicked = {
  category: Category.BROWSE;
  action: Action.EDUCATION_NAVIGATION_BROWSE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type EducationNavigationOptionClicked = {
  category: Category.EDUCATION_NAVIGATION;
  action:
    | Action.MIDDLE_SCHOOL
    | Action.HIGH_SCHOOL
    | Action.KEY_STAGE_3
    | Action.GCSE
    | Action.HIGHER_EDUCATION_US
    | Action.HIGHER_EDUCATION_UK
    | Action.BROWSE;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type TabClicked = {
  category: Category.BROWSE;
  action: Action.TAB_NAVIGATION;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type LinkClicked = {
  category: Category.LINK;
  action: 'series';
  name: string;
  value: 1;
  contentType: 'series';
  contentId: string;
  clickedFromPageTitle: string;
  clickedFromPageType: string;
  clickedFromLocation: 'playlist';
  clickedFromUrl: string;
};

export type SeriesLinkClicked = {
  category: Category.LIST_PANEL;
  action: Action.JUMP_TO;
  name: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export enum SectionLocation {
  RESOURCE_NAVIGATION_BAR = 'resource-navigation-bar',
}

export type SectionLinkClicked = {
  name: string;
  category: Category.RESOURCE_NAVIGATION;
  action: Action.JUMP_TO;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: SectionLocation;
};

export type ClassificationTagClicked = {
  name: string;
  category: Category.CLASSIFICATION;
  action: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type StudyUnitClicked = {
  name: string;
  category: Category.STUDY_UNIT;
  action: string;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type StudyUnitResourceClicked = {
  name: string;
  category: Category.CARD;
  action: string;
  value: 1;
  contentId: string;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type StudyUnitExtractClicked = {
  name: string;
  contentType: string;
  category: Category.CARD_EXTRACT;
  action: string;
  value: 1;
  contentId: string;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type StudyUnitBackToOverviewClicked = {
  name: string;
  category: Category.STUDY_UNIT;
  action: Action.BACK;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type StudyUnitSectionSidebarClicked = {
  name: string;
  category: Category.STUDY_UNIT;
  action: Action.SELECT;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type SearchClicked = {
  category: Category.SEARCH;
  action: Action.START_SEARCH | Action.SUBMIT | Action.CLEAR;
  name: string | undefined;
  value: 1;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
};

export type ShareInteracted = {
  category: Category.SHARE;
  action:
    | Action.OPEN
    | Action.CLOSE
    | Action.COPY
    | Action.SET_START
    | Action.SET_END;
  name: string | undefined;
  value: 1;
  contentId: string;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromLocation?: string;
  clickedFromUrl?: string;
};

export type BookmarkInteracted = {
  category: Category.BOOKMARKING;
  action: Action.ADD | Action.REMOVE | Action.UNDO_REMOVE | Action.LINK;
  name: string;
  value: 1;
  contentType: string;
  cardTitle: string;
  contentId: string;
  clickedFromPageType: string;
  clickedFromPageTitle: string;
  clickedFromUrl: string;
  clickedFromLocation: ClickedFromLocation;
};

export type TrackedEvent =
  | (ActionDimensions & {
      category?: Category;
      action?: Action | string | null;
      name?: string;
      value?: number;
    })
  | SeeAllButtonClicked
  | CheckboxClicked
  | AccordionClicked
  | NavigationClicked
  | TabClicked
  | PaginationClicked
  | CustomSelectClicked
  | SeriesLinkClicked
  | CardClicked
  | SectionLinkClicked
  | undefined;
