/* eslint-disable consistent-return */
import store from '../store';
import { waitUntilStoreInitialised } from './wait-until-store-is-initialised';

export const getCountryCodeFromStore = async (): Promise<string | null> => {
  if (typeof window !== 'undefined') {
    await waitUntilStoreInitialised();
  }

  if (!store.getState().user.name) {
    return null;
  }

  const { country } = store.getState().user.organisation;

  if (country) {
    return country;
  }

  return new Promise((res) => {
    store.subscribe(() => {
      const subscribedCountry = store.getState().user.organisation.country;
      if (subscribedCountry) {
        res(subscribedCountry);
      }
    });
  });
};
