import { useEffect, useState } from 'react';
import type { ReactNode, ReactNodeArray } from 'react';
import throttle from 'lodash/throttle';

import { MediaBreakpointContext } from './media-breakpoint-context';
import { MediaBreakpoints } from './types/media-breakpoints';
import { MediaBreakpointsScreensizes } from './types/media-breakpoints-screensizes';

type MediaBreakpointProviderProps = {
  children: ReactNodeArray | ReactNode;
  defaultBreakpoint?: MediaBreakpoints;
};

function getMediaBreakpoint(
  width: number | undefined,
  defaultBreakpoint: MediaBreakpoints
): MediaBreakpoints {
  if (width === undefined || width === 0) {
    return defaultBreakpoint;
  }

  if (width < MediaBreakpointsScreensizes.SM) {
    return MediaBreakpoints.XS;
  }
  if (width < MediaBreakpointsScreensizes.SE) {
    return MediaBreakpoints.SM;
  }
  if (width < MediaBreakpointsScreensizes.MD) {
    return MediaBreakpoints.SE;
  }
  if (width < MediaBreakpointsScreensizes.IM) {
    return MediaBreakpoints.IM;
  }
  if (width < MediaBreakpointsScreensizes.LG) {
    return MediaBreakpoints.MD;
  }
  if (width < MediaBreakpointsScreensizes.XL) {
    return MediaBreakpoints.LG;
  }
  return MediaBreakpoints.XL;
}

export function MediaBreakpointProvider({
  children,
  defaultBreakpoint = MediaBreakpoints.UNKNOWN,
}: MediaBreakpointProviderProps): JSX.Element {
  const [context, setContext] = useState<{
    mediaBreakpoint: MediaBreakpoints;
    windowWidth: number;
    windowHeight: number;
  }>({
    mediaBreakpoint: getMediaBreakpoint(window.innerWidth, defaultBreakpoint),
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
  });

  useEffect(() => {
    // eslint-disable-next-line prefer-arrow-callback
    const throttledHandleWindowResize = throttle(function handleResize() {
      setContext({
        mediaBreakpoint: getMediaBreakpoint(
          window.innerWidth,
          defaultBreakpoint
        ),
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }, 250);

    setContext({
      mediaBreakpoint: getMediaBreakpoint(window.innerWidth, defaultBreakpoint),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    window.addEventListener('resize', throttledHandleWindowResize);

    return () => {
      window.removeEventListener('resize', throttledHandleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MediaBreakpointContext.Provider value={context}>
      {children}
    </MediaBreakpointContext.Provider>
  );
}
