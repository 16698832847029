/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { NotificationConfig } from '../../ui/notifications/data/notifications';
import {
  INITIAL_NOTIFICATION_AREA_STATE,
  INITIAL_NOTIFICATION_STATE,
  INITIAL_NOTIFICATIONS_STATE,
} from './notifications.state';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: INITIAL_NOTIFICATIONS_STATE,
  reducers: {
    addNotificationArea: (state, action: PayloadAction<string>) => {
      state[action.payload] = {
        ...INITIAL_NOTIFICATION_AREA_STATE,
        name: action.payload,
      };
    },
    removeNotificationArea: (state, action: PayloadAction<string>) => {
      delete state[action.payload];
    },
    addNotification: (
      state,
      action: PayloadAction<{
        area: string;
        name: string;
        data: any;
        config: NotificationConfig;
      }>
    ) => {
      state[action.payload.area].notifications[action.payload.name] = {
        ...INITIAL_NOTIFICATION_STATE,
        id: action.payload.name,
        data: action.payload.data,
        config: action.payload.config,
        state: {
          show: false,
          dismissed: false,
          timestamp: dayjs().unix(),
        },
      };
    },
    setNotificationActiveInArea: (
      state,
      action: PayloadAction<{ area: string; name: string }>
    ) => {
      state[action.payload.area].notifications[
        action.payload.name
      ].config.active = true;
    },
    setNotificationInactiveInArea: (
      state,
      action: PayloadAction<{ area: string; name: string }>
    ) => {
      state[action.payload.area].notifications[
        action.payload.name
      ].config.active = false;
    },
    showNotificationInArea: (
      state,
      action: PayloadAction<{ area: string; name: string }>
    ) => {
      state[action.payload.area].notifications[action.payload.name].state.show =
        true;
    },
    hideNotificationInArea: (
      state,
      action: PayloadAction<{ area: string; name: string }>
    ) => {
      state[action.payload.area].notifications[action.payload.name].state.show =
        false;
    },
    dismissNotificationInArea: (
      state,
      action: PayloadAction<{ area: string; name: string }>
    ) => {
      state[action.payload.area].notifications[
        action.payload.name
      ].state.dismissed = true;
      state[action.payload.area].notifications[action.payload.name].state.show =
        false;
      state[action.payload.area].notifications[
        action.payload.name
      ].state.timestamp = dayjs().unix();
    },
  },
});

export const {
  addNotificationArea,
  removeNotificationArea,
  addNotification,
  setNotificationActiveInArea,
  setNotificationInactiveInArea,
  showNotificationInArea,
  hideNotificationInArea,
  dismissNotificationInArea,
} = notificationSlice.actions;

export default notificationSlice.reducer;
