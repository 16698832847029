/* eslint-disable no-console */
import type { Logger } from './logger';

export class ConsoleLogger implements Logger {
  debug(...messages: any[]): void {
    console.debug(...messages);
  }

  info(...messages: any[]): void {
    console.info(...messages);
  }

  log(...messages: any[]): void {
    console.log(...messages);
  }

  warn(...messages: any[]): void {
    console.warn(...messages);
  }

  error(...messages: any[]): void {
    console.error(...messages);
  }
}
