import type { Permissions } from '../data/user';

export const roles = [
  'superuser',
  'administrator',
  'teacher',
  'finance',
  'librarian',
  'technical',
  'faculty',
  'other',
];

export function permissionsToArray(
  permissions: Permissions & Record<string, unknown>
): Array<string> {
  return Object.entries(permissions).reduce<Array<string>>(
    (acc, [key, value]) => {
      if (roles.includes(key) && value) {
        if (Array.isArray(value) && value.length > 0) {
          return [...acc, key];
        }
        if (typeof value === 'boolean' && value) {
          return [...acc, key];
        }
      }
      return acc;
    },
    []
  );
}
