/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type {
  MasterAccount,
  Organisation,
  Subscription,
  SubscriptionsStatus,
} from '../../auth/data/user';
import { INITIAL_USER_STATE } from './user.state';
import { addBookmark as addBookmarkApi } from '../../bookmarks/api/frontend/add-bookmark';
import { removeBookmark as removeBookmarkApi } from '../../bookmarks/api/frontend/remove-bookmark';
import type { Bookmark } from '../../bookmarks';

export interface OrganisationState {
  organisation: Organisation;
  masterAccount: MasterAccount;
  subscriptionStatus: SubscriptionsStatus;
  subscriptions: Subscription[];
  superuser: boolean | null;
  administrator: string[] | null;
  teacher: string[] | null;
  finance: string[] | null;
  librarian: string[] | null;
  technical: string[] | null;
  faculty: string[] | null;
  other: string[] | null;
  notifications: Record<string, unknown>;
}

export interface AddBookmarkParams {
  bookmarkContent: {
    id: string;
    name: string;
    slug: string;
    contentType: string;
    resourceType: string;
    productionCompany: string;
    duration: string | null;
    relatedContent?: {
      id: string;
      name: string;
      queryString: string;
    };
  };
  accessToken: string;
}

export const addBookmark = createAsyncThunk(
  'user/bookmarks/add',
  async ({
    bookmarkContent,
    accessToken,
  }: AddBookmarkParams): Promise<Bookmark> => {
    const addedBookmarkItem = await addBookmarkApi(
      {
        id: bookmarkContent.id,
        relatedContentId: bookmarkContent.relatedContent?.id,
      },
      accessToken
    );

    if (!addedBookmarkItem) {
      throw new Error('Unable to add bookmark');
    }

    return {
      ...bookmarkContent,
      addedAt: addedBookmarkItem.addedAt,
    };
  }
);

export interface RemoveBookmarkParams {
  contentId: string;
  relatedContentId?: string;
  accessToken: string;
}

export const removeBookmark = createAsyncThunk(
  'user/bookmarks/remove',
  async ({
    contentId,
    relatedContentId,
    accessToken,
  }: RemoveBookmarkParams): Promise<
    Omit<RemoveBookmarkParams, 'accessToken'>
  > => {
    await removeBookmarkApi({ contentId, relatedContentId }, accessToken);
    return { contentId, relatedContentId };
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_USER_STATE,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setScopes: (state, action: PayloadAction<Array<string>>) => {
      state.scopes = action.payload;
    },
    setInfo: (state, action: PayloadAction<{ sub: string; name?: string }>) => {
      state.id = action.payload.sub;
      state.name = action.payload.name || '';
    },
    setIsInitialised: (state, action: PayloadAction<boolean>) => {
      state.isInitialised = action.payload;
    },
    setOrganisation: (state, action: PayloadAction<OrganisationState>) => {
      state.organisation = action.payload.organisation;
      state.masterAccount = action.payload.masterAccount;
      state.subscriptionStatus = action.payload.subscriptionStatus;
      state.subscriptions = action.payload.subscriptions;
      state.permissions.superuser = action.payload.superuser || false;
      state.permissions.administrator = action.payload.administrator || [];
      state.permissions.teacher = action.payload.teacher || [];
      state.permissions.finance = action.payload.finance || [];
      state.permissions.librarian = action.payload.librarian || [];
      state.permissions.technical = action.payload.technical || [];
      state.permissions.faculty = action.payload.faculty || [];
      state.permissions.other = action.payload.other || [];
      state.notifications = action.payload.notifications;
    },
    setUserNotificationData: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload.notifications;
    },
    setBookmarks: (state, action: PayloadAction<Array<Bookmark>>) => {
      state.bookmarks = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(addBookmark.fulfilled, (state, action) => {
      if (
        !state.bookmarks.some(
          (bookmark) =>
            bookmark.id === action.payload.id &&
            bookmark.relatedContent?.id === action.payload.relatedContent?.id
        )
      ) {
        state.bookmarks.push(action.payload as Bookmark);
      }
    });

    builder.addCase(removeBookmark.fulfilled, (state, action) => {
      state.bookmarks = state.bookmarks.filter(
        (bookmark) =>
          bookmark.id !== action.payload.contentId ||
          bookmark.relatedContent?.id !== action.payload.relatedContentId
      );
    });
  },
});

export const {
  setEmail,
  setScopes,
  setInfo,
  setIsInitialised,
  setOrganisation,
  setUserNotificationData,
  setBookmarks,
} = userSlice.actions;

export default userSlice.reducer;
