import { Action, Category, EducationNavigationViewed } from '../data/events';

//  User sees the ed. nav. component
export const createEducationNavigationViewEvent = (
  action: Action.EDUCATION_NAVIGATION_VIEW,
  name: string,
  pageType: string,
  pageTitle: string,
  viewedFromUrl: string
): EducationNavigationViewed => ({
  category: Category.VIEW,
  action,
  name,
  value: 1,
  viewedFromPageType: pageType,
  viewedFromPageTitle: pageTitle,
  viewedFromUrl,
});
