/* eslint-disable camelcase */
import type { ContentfulClientApi } from 'contentful';

import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';

import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import type { LessonPlan } from '../../../data/lesson-plan';

import mapSdkToLessonPlan from './mappers/map-sdk-to-lesson-plan';
import toContentfulOrdering from '../../../../utils/to-contentful-ordering';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import type {
  GetLessonPlan,
  GetLessonPlansForMultipleSlugs,
  GetNumberOfLessonPlansAssociatedWithContent,
  GetTotalNumberOfLessonPlans,
} from '../types';
import { getContentfulClient } from '../../../../config/contentful-config';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export const newGetLessonPlansForMultipleSlugs = (
  client: ContentfulClientApi
): GetLessonPlansForMultipleSlugs => {
  return async (slugs: string[]): Promise<LessonPlan[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.LESSON_PLAN,
        'fields.slug[in]': slugs.join(),
      },
      client
    );

    const { items } = response;

    const mappedLessonPlans = items.map(mapSdkToLessonPlan);

    return toContentfulOrdering(slugs, mappedLessonPlans);
  };
};

export const getLessonPlansForMultipleSlugsFromContentful: GetLessonPlansForMultipleSlugs =
  newGetLessonPlansForMultipleSlugs(getContentfulClient());

export const getLessonPlanFromContentful: GetLessonPlan = async (slug) => {
  const lessonPlans = await getLessonPlansForMultipleSlugsFromContentful([
    slug,
  ]);

  if (lessonPlans.length === 0) {
    throw Error('Invalid or missing slug');
  }

  return lessonPlans[0];
};

export const newGetLessonPlanNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );

    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.LESSON_PLAN,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};

export const getTotalNumberOfLessonPlansFromContentful: GetTotalNumberOfLessonPlans =
  async () => {
    const { total } = await getEntries({
      content_type: CONTENTFUL_CONTENT_TYPE.LESSON_PLAN,
      limit: 0,
    });

    return total;
  };

export const getNumberOfLessonPlansAssociatedWithContentFromContentful: GetNumberOfLessonPlansAssociatedWithContent =
  async (content, associatedContentType): Promise<number> => {
    const lessonPlansWithContent = await getEntries({
      content_type: 'lessonPlan',
      [associatedContentType]: content.id,
      limit: 0,
    });
    return lessonPlansWithContent.total;
  };
