import type { LessonPlan } from '../../../../data/lesson-plan';
import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../shared/api/adapters/map-to-related-content-slugs';
import mapToAdultThemes from '../../../../../shared/api/adapters/map-to-adult-themes';
import { ContentType } from '../../../../../content/data/content-type';

export default function mapSdkToLessonPlan(
  contentfulSdkLessonPlan: any
): LessonPlan {
  const {
    fields: {
      name,
      slug,
      type,
      mainImage,
      shortIntroduction,
      longIntroduction,
      lessonPlanMedia,
      authors,
      relatedContent,
      relatedContentGuidance,
      relatedProductionCompanies,
      relatedSkills,
      relatedGenres,
      relatedThemes,
      relatedEducationLevels,
      disablePdfPrintAndDownload,
      disablePdfHeadingsNavigation,
    },
    sys: { id, createdAt },
  } = contentfulSdkLessonPlan;

  const media = lessonPlanMedia
    ? {
        name: lessonPlanMedia.fields.name,
        url: lessonPlanMedia.fields.file.fields.file.url,
        pages: lessonPlanMedia.fields.pages ?? null,
      }
    : null;

  const mappedAuthors =
    authors?.map((author) => ({
      id: author.sys.id,
      slug: author.fields.slug,
      name: author.fields.name,
    })) || [];

  const {
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    id,
    name,
    slug,
    type: ContentType.LESSON_PLAN,
    subType: type,
    image: mapSdkToImage(mainImage),
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    media,
    authors: mappedAuthors,
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
    relatedSkillsSlugs: relatedSkills
      ? relatedSkills.map((skill) => skill.fields.slug)
      : [],
    relatedGenresSlugs: relatedGenres
      ? relatedGenres.map((genre) => genre.fields.slug)
      : [],
    relatedThemesSlugs: relatedThemes
      ? relatedThemes.map((theme) => theme.fields.slug)
      : [],
    relatedEducationLevelNames:
      relatedEducationLevels?.map((level) => level.fields.name) ?? [],
    hasAdultThemes: mapToAdultThemes(relatedContentGuidance),
    producerNames: relatedProductionCompanies
      ? relatedProductionCompanies.map((company) => company.fields.name)
      : [],
    firstPublishedAt: createdAt ? createdAt.substring(0, 10) : null,
    disablePrintAndDownload: disablePdfPrintAndDownload || false,
    hideOutline: disablePdfHeadingsNavigation || false,
  };
}
