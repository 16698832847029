import type {
  Organisation,
  MasterAccount,
  SubscriptionsStatus,
  Subscription,
  Permissions,
} from '../../auth/data/user';
import type { Bookmark } from '../../bookmarks';

const INITIAL_ORGANISATION_STATE: Organisation = {
  parentCompanyId: 123,
  id: '',
  name: '',
  type: '',
  category: '',
  adultThemesRestricted: false,
  country: '',
  country_alpha3: '',
  authMethod: '',
  blockedProfiles: [],
};

const INITIAL_MASTER_ACCOUNT_STATE: MasterAccount = {
  id: 0,
  name: '',
  type: '',
  category: '',
  country: '',
};

const INITIAL_SUBSCRIPTION_STATUS_STATE: SubscriptionsStatus = {
  hasActiveFullSubscription: false,
  hasActiveTrialSubscription: false,
  hasExpiredSubscription: false,
  hasSubscriptionThatWillStartInFuture: false,
};

export interface UserState {
  id: string;
  email: string;
  name: string;
  permissions: Permissions;
  alwaysAllowed: string[];
  alwaysBlocked: string[];
  isInitialised: boolean;
  organisation: Organisation;
  masterAccount: MasterAccount;
  subscriptionStatus: SubscriptionsStatus;
  subscriptions: Subscription[];
  scopes: Array<string>;
  notifications: Record<string, unknown>;
  bookmarks: Array<Bookmark>;
}

export const INITIAL_USER_STATE: UserState = {
  id: '',
  email: '',
  name: '',
  permissions: {
    superuser: false,
    administrator: [],
    teacher: [],
    finance: [],
    librarian: [],
    technical: [],
    faculty: [],
    other: [],
  },
  alwaysAllowed: [],
  alwaysBlocked: [],
  isInitialised: false,
  organisation: INITIAL_ORGANISATION_STATE,
  masterAccount: INITIAL_MASTER_ACCOUNT_STATE,
  subscriptionStatus: INITIAL_SUBSCRIPTION_STATUS_STATE,
  subscriptions: [],
  scopes: [],
  notifications: {},
  bookmarks: [],
};
