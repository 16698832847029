import type { Entry } from 'contentful';
import { mapSdkToImage } from '../map-to-image';
import type { PdfExtract } from '../../../data/pdf-extract';

export const mapSdkToPdfExtract = (
  contentfulPdfExtract: Entry<any>
): PdfExtract => {
  const {
    sys: { id },
    fields: { name, type, image, description, range },
  } = contentfulPdfExtract;

  return {
    name,
    id,
    type,
    description: description ?? null,
    image: mapSdkToImage(image),
    range,
  };
};
