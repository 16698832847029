import type { FC } from 'react';
import NotificationAreaContainer from '../../../ui/notifications/components/notification-area-container';
import UserSurveyBanner from './user-survey-banner';
import TrialUserFeedback from './trial-user-feedback-notification';

const BottomNotificationArea: FC = () => {
  return (
    <NotificationAreaContainer name="bottom" maxNotifications={1}>
      <TrialUserFeedback />
      <UserSurveyBanner />
    </NotificationAreaContainer>
  );
};

export default BottomNotificationArea;
