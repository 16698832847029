import type { ContentfulClientApi } from 'contentful';
import { getEntries } from '../../../respositories/frontend/contentful-repository';
import { CONTENTFUL_CONTENT_TYPE } from './types';
import toContentfulOrdering from '../../../utils/to-contentful-ordering';
import type { Company } from '../../data/company';
import { mapSdkToCompany } from './map-sdk-to-company';

export function getCompaniesForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: string[]): Promise<Company[]> => {
    const companyResponse = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.COMPANY,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const mappedCompanies = companyResponse.items.map(mapSdkToCompany);

    return toContentfulOrdering(slugs, mappedCompanies);
  };
}
