import { gql, isApolloError } from '@apollo/client';
import customerApi from '../../../config/customer-api';

const MUTATION = gql`
  mutation DeleteBookmarkItem($contentId: String!, $relatedContentId: String) {
    deleteBookmarkItem(
      contentId: $contentId
      relatedContentId: $relatedContentId
    ) {
      items {
        contentId
        relatedContentId
      }
    }
  }
`;

export async function removeBookmark(
  {
    contentId,
    relatedContentId,
  }: { contentId: string; relatedContentId?: string },
  accessToken: string
): Promise<void> {
  const client = customerApi.v3.createApolloClient(accessToken);

  try {
    const { errors } = await client.mutate({
      mutation: MUTATION,
      variables: {
        contentId,
        relatedContentId,
      },
    });

    if (errors) {
      // eslint-disable-next-line no-console
      console.error(
        `Unable to remove bookmark: ${JSON.stringify(errors, null, 2)}`
      );

      throw new Error('Unable to remove bookmark');
    }
  } catch (e) {
    if (e instanceof Error && isApolloError(e)) {
      if (e.graphQLErrors.length === 1) {
        const error = e.graphQLErrors[0];
        if (error.message.includes('Item does not exist in playlist')) {
          // There is nothing to remove
          // Should not be considered an error
          return;
        }
      }
    }

    throw e;
  }
}
