/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Link from 'next/link';
import type { ReactNode, MouseEvent, FC } from 'react';
import type { TrackedEvent } from '../../../analytics/data/events';
import { trackEvent } from '../../../analytics/api';

export interface Props {
  id?: string;
  variant:
    | 'primary'
    | 'secondary-dark'
    | 'secondary-dark-reverse'
    | 'secondary-light'
    | 'tertiary-dark'
    | 'tertiary-light'
    | 'invisible-dark'
    | 'invisible-light';
  children: ReactNode | string;
  handleButtonClick?: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  size?: 'md' | 'lg';
  href?: string;
  as?: string;
  className?: string;
  type?: 'button' | 'submit';
  loading?: boolean;
  ariaLive?: 'off' | 'assertive' | 'polite';
  analyticsTrackedEvent?: TrackedEvent;
  ariaLabel?: string;
}

const Button: FC<Props> = ({
  id,
  handleButtonClick,
  variant,
  size = 'md',
  children,
  disabled = false,
  href,
  as,
  className = '',
  type = 'button',
  loading,
  ariaLive,
  analyticsTrackedEvent,
  ariaLabel,
}: Props) => {
  if (href) {
    return (
      <Link href={href} as={as} aria-label={ariaLabel}>
        <a
          className={`c-button c-button--${variant} c-button--${size} ${className}`.trim()}
          data-testid="link-button"
          onClick={() => trackEvent(analyticsTrackedEvent)}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <button
      id={id}
      type={type}
      className={`c-button c-button--${variant} c-button--${size} ${
        loading ? 'c-button--loading' : ''
      } ${className}`.trim()}
      onClick={(e) => {
        trackEvent(analyticsTrackedEvent);
        if (handleButtonClick) {
          handleButtonClick(e);
        }
      }}
      disabled={disabled}
      data-testid="handlerButton"
      aria-live={ariaLive}
    >
      {children}
    </button>
  );
};

export default Button;
