import URI from 'urijs';
import type { Image } from '../../data/image';

const mapSdkToImageUrl = (contentfulImage: any): string | null => {
  const focus = contentfulImage?.fields.focus ?? 'faces';
  return contentfulImage
    ? `${contentfulImage.fields.relatedImage.fields.file.url}?f=${focus}&fit=fill`
    : null;
};

export const mapSdkToImage = (contentfulImage: any): Image | null => {
  const imageUrl = mapSdkToImageUrl(contentfulImage);

  if (!imageUrl) {
    return null;
  }

  return {
    url: URI(imageUrl).subdomain('images').toString(),
    altText: contentfulImage.fields.altText,
    title: contentfulImage.fields.title,
  };
};

const mapGqlToImageUrl = (contentfulImage: any): string | null => {
  const focus = contentfulImage?.focus ?? 'faces';
  return contentfulImage
    ? `${contentfulImage.relatedImage.url}?f=${focus}&fit=fill`
    : null;
};

export const mapGqlToImage = (contentfulImage: any): Image | null => {
  const imageUrl = mapGqlToImageUrl(contentfulImage);

  if (!imageUrl) {
    return null;
  }

  return {
    url: URI(imageUrl).subdomain('images').toString(),
    altText: contentfulImage.altText,
    title: contentfulImage.title,
  };
};
