import type Group from '../../accounts/data/group';

export interface CompanyState {
  groups: Group[];
}

export const INITIAL_GROUP_STATE: Group = {
  id: '',
  name: '',
  restrictAccessToAdultThemes: false,
  alwaysAllowedContent: [],
  alwaysBlockedContent: [],
};

export const INITIAL_COMPANY_STATE: CompanyState = {
  groups: [],
};
