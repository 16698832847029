import { OktaAuth } from '@okta/okta-auth-js';
import URI from 'urijs';

export const CLIENT_ID = process.env.NEXT_PUBLIC_OKTA_CLIENT_ID;
export const HOST = process.env.NEXT_PUBLIC_HOST_ADDRESS;
export const CALLBACK_PATH = process.env.NEXT_PUBLIC_OKTA_CALLBACK_PATH;

if (HOST === undefined || CALLBACK_PATH === undefined) {
  throw Error('you need to supply a host or callback path');
}
const redirectUri = URI(HOST).path(CALLBACK_PATH).toString();
const postLogoutRedirectUri = URI(HOST).path('/logged-out').toString();

if (process.env.NEXT_PUBLIC_OKTA_ISSUER === undefined) {
  throw Error('you need to supply an okta issuer');
}
export const OKTA_ISSUER = process.env.NEXT_PUBLIC_OKTA_ISSUER;
const issuer = URI(OKTA_ISSUER).toString();

export const oktaConfig = new OktaAuth({
  clientId: `${CLIENT_ID}`,
  issuer,
  redirectUri,
  scopes: ['openid', 'email', 'profile'],
  pkce: true,
  storageManager: {
    token: {
      storageTypes: [
        'cookie'
      ],
    },
    cache: {
      storageTypes: [
        'localStorage',
        'cookie'
      ]
    },
    transaction: {
      storageTypes: [
        'cookie'
      ]
    }
  },
  tokenManager: {
    autoRenew: true,
  },
  postLogoutRedirectUri,
});
