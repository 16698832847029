import { getContentfulClient } from '../../../config/contentful-config';
import getContentFromBff from '../../../content/factories/get-content-from-bff';
import type { Guide } from '../../data/guide';
import { newGetNumberOfGuidesAssociatedWithContent } from './adapters/contentful-get-guides-with-content';
import {
  getGuideFromContentful,
  newGetGuideNameFromContentful,
  newGetGuidesForMultipleSlugsFromContentful,
  newGetTotalNumberOfGuidesFromContentful,
} from './adapters/contentful-guide-content-api';
import type {
  GetGuide,
  GetGuides,
  GetGuidesForMultipleSlugs,
  GetNumberOfGuidesAssociatedWithContent,
  GetTotalNumberOfGuides,
} from './types';
import type { GetContentNameBySlug } from '../../../auth/utils/authentication';

export const getGuide: GetGuide = getGuideFromContentful;

export const getGuideName: GetContentNameBySlug = newGetGuideNameFromContentful(
  getContentfulClient()
);

export const getGuides: GetGuides = getContentFromBff<Guide>('guides');

export const getGuidesForMultipleSlugs: GetGuidesForMultipleSlugs =
  newGetGuidesForMultipleSlugsFromContentful(getContentfulClient());

export const getTotalNumberOfGuides: GetTotalNumberOfGuides =
  newGetTotalNumberOfGuidesFromContentful(getContentfulClient());

export const getNumberOfGuidesAssociatedWithContent: GetNumberOfGuidesAssociatedWithContent =
  newGetNumberOfGuidesAssociatedWithContent(getContentfulClient());
