import store from '../store';
import type { UserState } from '../store/user/user.state';

function isUserStateInitialised(userState: UserState): boolean {
  if (userState.isInitialised) {
    if (userState.name) {
      if (userState.organisation.id) {
        return true;
      }
    } else {
      return true;
    }
  }

  return false;
}

// eslint-disable-next-line consistent-return
export const waitUntilStoreInitialised = async (): Promise<void> => {
  if (!isUserStateInitialised(store.getState().user)) {
    return new Promise((res) => {
      store.subscribe(() => {
        if (isUserStateInitialised(store.getState().user)) {
          res();
        }
      });
    });
  }
};
