import { ContentType } from '../../../../../content/data/content-type';
import mapToAdultThemes from '../../../../../shared/api/adapters/map-to-adult-themes';
import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../shared/api/adapters/map-to-related-content-slugs';
import type { Guide } from '../../../../data/guide';

export default function mapSdkToGuide(contentfulSdkGuide: any): Guide {
  const {
    fields: {
      name,
      slug,
      guideDate,
      type,
      mainImage,
      shortIntroduction,
      longIntroduction,
      guideMedia,
      authors,
      relatedContent,
      relatedContentGuidance,
      relatedProductionCompanies,
      disablePdfPrintAndDownload,
      disablePdfHeadingsNavigation,
      relatedSkills,
      relatedGenres,
      relatedThemes,
      relatedEducationLevels,
    },
    sys: { id, createdAt },
  } = contentfulSdkGuide;

  const media = guideMedia
    ? {
        name: guideMedia.fields.name,
        url: guideMedia.fields.file.fields.file.url,
        pages: guideMedia.fields.pages ?? null,
      }
    : null;

  const mappedAuthors =
    authors?.map((author) => ({
      id: author.sys.id,
      slug: author.fields.slug,
      name: author.fields.name,
    })) || [];

  const {
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    id,
    name,
    slug,
    type: ContentType.GUIDE,
    subType: type,
    image: mapSdkToImage(mainImage),
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    date: guideDate ?? null,
    authors: mappedAuthors,
    media,
    producerNames:
      relatedProductionCompanies?.map((company) => company.fields.name) ?? [],
    firstPublishedAt: createdAt?.substring(0, 10) ?? null,
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
    relatedSkillsSlugs: relatedSkills?.map((skill) => skill.fields.slug) ?? [],
    relatedGenresSlugs: relatedGenres?.map((genre) => genre.fields.slug) ?? [],
    relatedThemesSlugs: relatedThemes?.map((theme) => theme.fields.slug) ?? [],
    relatedEducationLevelNames:
      relatedEducationLevels?.map((level) => level.fields.name) ?? [],
    hasAdultThemes: mapToAdultThemes(relatedContentGuidance),
    disablePrintAndDownload: disablePdfPrintAndDownload || false,
    hideOutline: disablePdfHeadingsNavigation || false,
  };
}
