import type { ContentfulClientApi } from 'contentful';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';
import type { GetNumberOfGuidesAssociatedWithContent } from '../types';

export const newGetNumberOfGuidesAssociatedWithContent = (
  client: ContentfulClientApi
): GetNumberOfGuidesAssociatedWithContent => {
  return async (content, associatedContentType): Promise<number> => {
    const guidesWithContent = await getEntries(
      { content_type: 'guide', [associatedContentType]: content.id, limit: 0 },
      client
    );
    return guidesWithContent.total;
  };
};
