import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useState, useEffect, useMemo } from 'react';

import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { userSelector } from '../../../store/user/selectors';
import range from '../../../utils/range';
import {
  getUsatSurveyMetadata,
  setUsatSurveyMetadata as setUsatSurveyMetadataToApi,
  submitUserSurvey,
} from '../../api';
import type { UsatSurveyMetadata } from '../../data/usat-survey-metadata';
import { shouldActivateSurveyBanner } from '../../utils/should-activate-notifications';
import Button from '../button/button';
import NotificationBanner from './notification-banner';

const BAD_MESSAGE =
  "Thank you for sharing your feedback. We're always looking to improve the experience of Digital Theatre+ with the help of our customers";
const GOOD_MESSAGE = 'Great! Thank you for sharing your feedback!';

type SurveyQuestionProps = {
  onSubmit: (score: number) => void;
};

const SurveyQuestion = ({ onSubmit }: SurveyQuestionProps) => (
  <div className="u-flex upto-sm:u-flex-col u-items-center u-justify-center">
    How satisfied are you with Digital&nbsp;Theatre+?
    <div className="u-flex u-space-x-2 u-mt-3 u-ml-12 sm:u-ml-4 sm:u--my-2">
      {[...range(1, 6)].map((n) => (
        <div data-testid="usat-survey-banner-submit" key={n}>
          <Button
            variant="tertiary-dark"
            className="u-w-7"
            handleButtonClick={() => onSubmit(n)}
          >
            {n}
          </Button>
        </div>
      ))}
    </div>
  </div>
);

const UserSurveyBanner = () => {
  const {
    query: { debug },
  } = useRouter();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [thanksMessage, setThanksMessage] = useState('');
  const [autoDismiss, setAutoDismiss] = useState(false);
  const [usatSurveyMetadata, setUsatSurveyMetadata] =
    useState<UsatSurveyMetadata>();
  const user = useTypedSelector(userSelector);

  useEffect(() => {
    if (user.id !== '' && !debug) {
      getUsatSurveyMetadata(user.id).then((x) => {
        setUsatSurveyMetadata(x);
      });
    }
  }, [user.id, debug]);

  const active = useMemo<boolean>(
    () =>
      Boolean(debug) ||
      (usatSurveyMetadata !== undefined &&
        shouldActivateSurveyBanner(
          user.id,
          user.subscriptions,
          usatSurveyMetadata
        )),
    [usatSurveyMetadata, user.id, user.subscriptions, debug]
  );

  const onSubmitHandler = (score: number) => {
    setHasSubmitted(true);
    setThanksMessage(score > 3 ? GOOD_MESSAGE : BAD_MESSAGE);
    setAutoDismiss(true);

    if (!debug) {
      submitUserSurvey(user, score);
      setUsatSurveyMetadataToApi({
        userId: user.id,
        dismissalCounter: 0,
        nextDisplayTimestamp: dayjs().add(6, 'months'),
      });
    }
  };

  const onDismissHandler = () => {
    if (!hasSubmitted && !debug && usatSurveyMetadata) {
      const nextDisplayTimestamp =
        usatSurveyMetadata.dismissalCounter % 3 === 2
          ? dayjs().add(3, 'months')
          : dayjs().add(2, 'weeks');

      setUsatSurveyMetadataToApi({
        userId: user.id,
        dismissalCounter: usatSurveyMetadata.dismissalCounter + 1,
        nextDisplayTimestamp,
      });
    }
  };

  return (
    <NotificationBanner
      name="usatSurveyBanner"
      active={active}
      bottom
      dismissable
      onDismiss={onDismissHandler}
      autoDismiss={autoDismiss}
      dismissTestId="usat-survey-banner-dismiss"
      ariaLabel="User Survey"
    >
      <div className="c-notification-banner__inner__layered-message">
        <div aria-hidden={hasSubmitted}>
          <SurveyQuestion onSubmit={onSubmitHandler} />
        </div>

        <div aria-hidden={!hasSubmitted} className="u-text-center">
          {thanksMessage}
        </div>
      </div>
    </NotificationBanner>
  );
};

export default UserSurveyBanner;
