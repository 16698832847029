import type { ContentfulClientApi, Entry } from 'contentful';
import { getUnauthorisedRightsAgreements } from '../../rights-agreement/api';

const MAX_ENTRIES_PER_REQUEST = 50;
const RESTRICTED_CONTENT_TYPES = [
  'production',
  'lessonPlan',
  'workshop',
  'interview',
  'guide',
  'collection',
  'person',
  'series',
  'title',
];
const CONTENT_TYPES_WITH_PROFILES = [
  'production',
  'lessonPlan',
  'workshop',
  'interview',
  'guide',
  'series',
  'genre',
  'theme',
  'title',
  'collection',
];

export type GetEntriesResponse = Promise<{
  items: Entry<any>[];
  total: number;
}>;

export interface ContentfulQuery {
  content_type?: string;
  limit?: number;
  skip?: number;
}

let cachedCountryCode: string;
let cachedRightsAgreementsIds: Promise<string[]>;

export const getUnauthorisedRightsAgreementIdsForCountry = async (
  countryCode: string
): Promise<string> => {
  const unresolvedIds =
    cachedCountryCode === countryCode
      ? cachedRightsAgreementsIds
      : getUnauthorisedRightsAgreements(countryCode);

  cachedCountryCode = countryCode;
  cachedRightsAgreementsIds = unresolvedIds;
  const resolvedIds = await unresolvedIds;

  return resolvedIds.join();
};

const addGeographyRestrictions = async <T extends {}>(
  query: T,
  countryCode: string | null
): Promise<T> => {
  if (countryCode) {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(countryCode);

    return {
      ...query,
      'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
    };
  }

  return query;
};

const addContentProfilesRestrictions = async <T extends {}>(
  query: T,
  blockedProfiles: string[] | null
): Promise<T> => {
  if (Array.isArray(blockedProfiles) && blockedProfiles.length > 0) {
    return {
      ...query,
      'fields.relatedContentProfiles.sys.id[nin]': blockedProfiles.join(),
    };
  }

  return query;
};

const paginateGetEntries = async <T extends ContentfulQuery>(
  query: T,
  client: ContentfulClientApi
): GetEntriesResponse => {
  const { total } = await client.getEntries({ ...query, limit: 0 });

  const querySkip = query.skip ?? 0;

  if (total <= querySkip) {
    return { items: [], total };
  }

  const numberOfEntriesRequired = Math.min(
    total - querySkip,
    query.limit ?? Infinity
  );
  const numberOfRequests = Math.ceil(
    numberOfEntriesRequired / MAX_ENTRIES_PER_REQUEST
  );

  const getEntriesRequest = Array.from(
    { length: numberOfRequests - 1 },
    (_, i) => {
      const skip = i * MAX_ENTRIES_PER_REQUEST + querySkip;
      return client.getEntries({
        ...query,
        limit: MAX_ENTRIES_PER_REQUEST,
        skip,
      });
    }
  );

  const limit =
    numberOfEntriesRequired % MAX_ENTRIES_PER_REQUEST ||
    MAX_ENTRIES_PER_REQUEST;
  const skip = (numberOfRequests - 1) * MAX_ENTRIES_PER_REQUEST + querySkip;

  getEntriesRequest.push(client.getEntries({ ...query, limit, skip }));

  const responses = await Promise.all(getEntriesRequest);

  const items = responses.flatMap((response) => response.items);

  return {
    items,
    total,
  };
};

export const getEntriesIsomorphic = async <T extends ContentfulQuery>(
  options: T,
  client: ContentfulClientApi,
  restrictions: {
    countryCode: string | null;
    blockedProfiles: string[] | null;
  } = {
    countryCode: null,
    blockedProfiles: [],
  }
): GetEntriesResponse => {
  let query = { ...options };

  if (query.content_type) {
    const contentType = query.content_type;
    if (RESTRICTED_CONTENT_TYPES.includes(contentType)) {
      query = await addGeographyRestrictions(query, restrictions.countryCode);
    }

    if (CONTENT_TYPES_WITH_PROFILES.includes(contentType)) {
      query = await addContentProfilesRestrictions(
        query,
        restrictions.blockedProfiles
      );
    }
  }

  if (query.limit !== undefined && query.limit <= MAX_ENTRIES_PER_REQUEST) {
    const { items, total } = await client.getEntries(query);
    return {
      items,
      total,
    };
  }

  return paginateGetEntries(query, client);
};
