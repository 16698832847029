import { ContentType } from '../../../../../content/data/content-type';
import mapToAdultThemes from '../../../../../shared/api/adapters/map-to-adult-themes';
import mapToHealthGuidance from '../../../../../shared/api/adapters/map-to-health-guidance';
import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../shared/api/adapters/map-to-related-content-slugs';

import toTitleCase from '../../../../../utils/to-title-case';
import type {
  Production,
  ProductionGenre,
  RelatedLocation,
} from '../../../../data/production';

const mapLocations = (locations): Array<RelatedLocation> => {
  return locations?.map((location) => {
    return { name: location.fields.name };
  });
};

const mapGenres = (genres): Array<ProductionGenre> => {
  return genres?.map((genre) => {
    return { name: genre.fields.name, slug: genre.fields.slug };
  });
};

export function mapSdkToProduction(contentfulProduction: any): Production {
  const {
    fields: {
      name,
      slug,
      type,
      mainImage,
      shortIntroduction,
      longIntroduction,
      productionDate,
      productionMedia,
      relatedLocations,
      relatedTitle,
      writers,
      directors,
      relatedProductionCompanies,
      relatedContent,
      relatedCastMembersJson,
      relatedCreativeMembersJson,
      relatedSegments,
      relatedContentGuidance,
      relatedSkills,
      relatedGenres,
      relatedThemes,
      relatedEducationLevels,
    },
    sys: { id, createdAt },
  } = contentfulProduction;

  const {
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedProductionSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    id,
    name,
    slug,
    type: ContentType.PRODUCTION,
    subType: type,
    image: mapSdkToImage(mainImage),
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    date: productionDate ?? null,
    firstPublishedAt: createdAt ? createdAt.substring(0, 10) : null,
    media: {
      type: toTitleCase(productionMedia.sys.contentType.sys.id) as
        | 'Video'
        | 'Audio',
      assetId: productionMedia.fields.assetId ?? null,
      dashManifestUrl: productionMedia.fields.dashManifestUrl ?? null,
      hlsManifestUrl: productionMedia.fields.hlsManifestUrl ?? null,
      subtitleUrl: productionMedia.fields.subtitleUrl ?? null,
      durationMinutes: productionMedia.fields.durationMinutes ?? null,
    },
    relatedLocations: mapLocations(relatedLocations) ?? [],
    genres: mapGenres(relatedGenres) ?? [],
    relatedSkillsSlugs: relatedSkills?.map((skill) => skill.fields.slug) ?? [],
    relatedGenresSlugs: relatedGenres?.map((genre) => genre.fields.slug) ?? [],
    relatedThemesSlugs: relatedThemes?.map((theme) => theme.fields.slug) ?? [],
    relatedEducationLevelNames:
      relatedEducationLevels?.map((level) => level.fields.name) ?? [],
    directorSlugs: directors?.map((director) => director.fields.slug) ?? [],
    producerNames: relatedProductionCompanies.map(
      (company) => company.fields.name
    ),
    writerSlugs: writers?.map((writer) => writer.fields.slug) ?? [],
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedTitleSlug: relatedTitle ? relatedTitle.fields.slug : null,
    relatedLessonPlanSlugs,
    relatedProductionSlugs,
    relatedMultipleSeriesSlugs,
    relatedCastMembersJson: relatedCastMembersJson ?? [],
    relatedCreativeMembersJson: relatedCreativeMembersJson ?? [],
    relatedSegmentIds: relatedSegments?.map(({ sys }) => sys.id) ?? [],
    hasAdultThemes: mapToAdultThemes(relatedContentGuidance),
    healthGuidance: mapToHealthGuidance(relatedContentGuidance),
  };
}
