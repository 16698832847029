import { createContext, useContext, useMemo } from 'react';
import { ClickedFromPageTitle, ClickedFromPageType } from '../data/events';

export interface AnalyticsContextProps {
  pageTitle: string;
  pageType: string;
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  pageTitle: ClickedFromPageTitle.UNKNOWN,
  pageType: ClickedFromPageType.UNKNOWN,
});

export const useAnalyticsContext = (): AnalyticsContextProps => {
  return useContext(AnalyticsContext);
};

export interface AnalyticsProps {
  pageTitle: string;
  pageType: string;
  children: React.ReactNode;
}

const AnalyticsContextProvider: React.FC<AnalyticsProps> = ({
  pageTitle,
  pageType,
  children,
}: AnalyticsProps) => {
  const value = useMemo(
    () => ({
      pageTitle,
      pageType,
    }),
    [pageTitle, pageType]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
