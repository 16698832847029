import { getContentfulClient } from '../../../config/contentful-config';
import type { LessonPlan } from '../../data/lesson-plan';
import {
  getLessonPlanFromContentful,
  getLessonPlansForMultipleSlugsFromContentful,
  getNumberOfLessonPlansAssociatedWithContentFromContentful,
  getTotalNumberOfLessonPlansFromContentful,
  newGetLessonPlanNameFromContentful,
} from './adapters/contentful-lesson-plan-api';
import getContentFromBff from '../../../content/factories/get-content-from-bff';
import type { GetContentNameBySlug } from '../../../auth/utils/authentication';
import type {
  GetLessonPlan,
  GetLessonPlans,
  GetLessonPlansForMultipleSlugs,
  GetNumberOfLessonPlansAssociatedWithContent,
  GetTotalNumberOfLessonPlans,
} from './types';

export const getLessonPlan: GetLessonPlan = getLessonPlanFromContentful;

export const getLessonPlans: GetLessonPlans =
  getContentFromBff<LessonPlan>('lesson-plans');

export const getLessonPlanName: GetContentNameBySlug =
  newGetLessonPlanNameFromContentful(getContentfulClient());

export const getLessonPlansForMultipleSlugs: GetLessonPlansForMultipleSlugs =
  getLessonPlansForMultipleSlugsFromContentful;

export const getTotalNumberOfLessonPlans: GetTotalNumberOfLessonPlans =
  getTotalNumberOfLessonPlansFromContentful;

export const getNumberOfLessonPlansAssociatedWithContent: GetNumberOfLessonPlansAssociatedWithContent =
  getNumberOfLessonPlansAssociatedWithContentFromContentful;
