import { FC, ReactNode, ReactNodeArray, useEffect } from 'react';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import {
  addNotificationArea,
  hideNotificationInArea,
  showNotificationInArea,
} from '../../../store/notifications/notifications.slice';
import { notificationAreaSelector } from '../../../store/notifications/selectors';
import { NotificationAreaContext } from '../context/notification-area-context';
import type { NotificationArea, Notification } from '../data/notifications';

function sortPriorities(a: Notification, b: Notification) {
  if (b.config.priority === a.config.priority) {
    return b.state.timestamp - a.state.timestamp;
  }
  return b.config.priority - a.config.priority;
}

function getShownNotifications(notificationArea: NotificationArea) {
  return Object.values<Notification>(notificationArea.notifications)
    .filter((notification) => {
      return notification.config.active && notification.state.show;
    })
    .sort(sortPriorities);
}

function getShownDeactivatedNotifications(notificationArea: NotificationArea) {
  return Object.values<Notification>(notificationArea.notifications)
    .filter((notification) => {
      return !notification.config.active && notification.state.show;
    })
    .sort(sortPriorities);
}

function getActiveNonDismissedNotifications(
  notificationArea: NotificationArea
) {
  return Object.values<Notification>(notificationArea.notifications)
    .filter((notification) => {
      return notification.config.active && !notification.state.dismissed;
    })
    .sort(sortPriorities);
}

interface Props {
  name: string; // A unique identifying name for the notification area
  maxNotifications: number; // The maximum number of notifications to display at one time
  children: ReactNode | ReactNodeArray;
}

const NotificationAreaContainer: FC<Props> = ({
  name,
  maxNotifications,
  children,
}: Props) => {
  const notificationArea = useTypedSelector((state) =>
    notificationAreaSelector(state, name)
  );

  const dispatch = useAppDispatch();

  // Newly initialised notification areas need to have their data added to the store
  useEffect(() => {
    if (notificationArea === undefined) {
      dispatch(addNotificationArea(name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Changes in the notification area need to be checked for
  // changes to the number of notifications
  useEffect(() => {
    if (notificationArea !== undefined) {
      const shownNotifications = getShownNotifications(notificationArea);

      if (shownNotifications.length < maxNotifications) {
        const activeNonDismissedNotifications =
          getActiveNonDismissedNotifications(notificationArea);
        activeNonDismissedNotifications
          .slice(0, maxNotifications - shownNotifications.length)
          .forEach((notification) => {
            if (!notification.state.show) {
              dispatch(
                showNotificationInArea({ area: name, name: notification.id })
              );
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationArea]);

  useEffect(() => {
    if (notificationArea !== undefined) {
      const shownDeactivatedNotifications =
        getShownDeactivatedNotifications(notificationArea);

      if (shownDeactivatedNotifications.length > 0) {
        shownDeactivatedNotifications.forEach((notification) => {
          if (notification.state.show) {
            dispatch(
              hideNotificationInArea({ area: name, name: notification.id })
            );
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationArea]);

  if (notificationArea === undefined) {
    return null;
  }

  return (
    <NotificationAreaContext.Provider value={name}>
      {children}
    </NotificationAreaContext.Provider>
  );
};

export default NotificationAreaContainer;
