/* eslint-disable prefer-destructuring */

import assert from 'assert';
import fetch from 'isomorphic-unfetch';
import { ConsoleLogger } from '../../../../shared/services/logging/console-logger';
import type { GetOrganisationWithIp } from '../types';

const logger = new ConsoleLogger();
const CUSTOMER_API_URL = process.env.NEXT_PUBLIC_CUSTOMERS_API_URL;
const CUSTOMER_API_KEY = process.env.CUSTOMER_API_KEY;
assert(typeof CUSTOMER_API_URL === 'string');

export const getOrganisationWithIpFromUserApi: GetOrganisationWithIp = async (
  ip
) => {
  const url = `${CUSTOMER_API_URL}/organisations?ip-address=${ip}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${CUSTOMER_API_KEY}`,
    },
  });
  const jsonResponse = await response.json();

  if (jsonResponse.organisations.length === 0) {
    return null;
  }

  if (jsonResponse.organisations.length > 1) {
    logger.warn(
      `The IP address ${ip} exists in more than one organisation CIDR in the CAPI.`
    );
  }

  return {
    organisationId: jsonResponse.organisations[0].id,
    organisationName: jsonResponse.organisations[0].testName,
  };
};
