import React, {
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { TourContext } from './tour-context';
import type { TourStep } from './types/tour-step';
import { trackEvent } from '../../../analytics/api';
import {
  createTourStartedEvent,
  createTourStepCompletedEvent,
  createTourViewedEvent,
} from '../../../analytics/events/create-tour-event';
import toKebabCase from '../../../utils/to-kebab-case';
import {
  ClickedFromPageTitle,
  ClickedFromPageType,
} from '../../../analytics/data/events';

interface TourProviderProps {
  children: ReactNode;
}

export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [tourSteps, setTourSteps] = useState<TourStep[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [started, setStarted] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>(
    ClickedFromPageTitle.UNKNOWN
  );
  const [pageType, setPageType] = useState<string>(ClickedFromPageType.UNKNOWN);

  const router = useRouter();

  useEffect(() => {
    const preventScroll = (event) => {
      event.preventDefault();
    };

    const preventKeyDownScroll = (event) => {
      if (event.key === 'PageDown') {
        event.preventDefault();
      }
    };

    if (started) {
      window.scrollTo({ top: 0 });
      window.addEventListener('mousewheel', preventScroll, {
        passive: false,
      });
      window.addEventListener('keydown', preventKeyDownScroll, {
        passive: false,
      });
    }

    return () => {
      window.removeEventListener('mousewheel', preventScroll);
      window.removeEventListener('keydown', preventKeyDownScroll);
    };
  }, [started]);

  const setUpTour = (
    steps: TourStep[],
    _pageTitle: string,
    _pageType: string
  ) => {
    setPageTitle(_pageTitle);
    setPageType(_pageType);
    setTourSteps(steps);
  };

  function canShowTour(): boolean {
    const showTour = localStorage.getItem('showTour');

    if (showTour === null) {
      localStorage.setItem(
        'showTour',
        dayjs().subtract(1, 'day').toISOString()
      );
      return true;
    }

    if (showTour !== 'false') {
      const showTourDate = dayjs(showTour);
      if (dayjs().isAfter(showTourDate)) {
        return true;
      }
    }

    return false;
  }

  const startTour = useCallback(() => {
    if (canShowTour()) {
      trackEvent(
        createTourViewedEvent(
          'education-navigation',
          pageType,
          pageTitle,
          router.asPath
        )
      );
      setCurrentStep(0);
      setStarted(true);
    }
  }, [pageType, pageTitle, router.asPath]);

  const nextStep = useCallback(() => {
    if (currentStep === 0) {
      trackEvent(
        createTourStartedEvent(
          'education-navigation',
          pageType,
          pageTitle,
          router.asPath
        )
      );
    } else {
      trackEvent(
        createTourStepCompletedEvent(
          `education-navigation-${toKebabCase(tourSteps[currentStep].title)}`,
          pageType,
          pageTitle,
          router.asPath
        )
      );
    }
    setCurrentStep((previous) => Math.min(previous + 1, tourSteps.length - 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourSteps, currentStep]);

  const prevStep = useCallback(() => {
    setCurrentStep((previous) => Math.max(previous - 1, 0));
  }, []);

  const quitTour = useCallback(() => {
    setStarted(false);
    setCurrentStep(0);
  }, []);

  const contextValue = useMemo(
    () => ({
      tourSteps,
      currentStep,
      startTour,
      nextStep,
      prevStep,
      quitTour,
      started,
      setUpTour,
    }),
    [tourSteps, currentStep, startTour, nextStep, prevStep, quitTour, started]
  );

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        quitTour();
      }
    };

    if (started) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [started, quitTour]);

  return (
    <TourContext.Provider value={contextValue}>{children}</TourContext.Provider>
  );
};
