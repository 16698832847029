/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { Series } from '../../series/data/series';
import type { SlugValue } from '../../shared/hooks/use-slug';
import { EntityLoadingStatus } from '../state/entity-wrapper';
import { INITIAL_SERIES_STATE } from './series.state';

export const seriesSlice = createSlice({
  name: 'series',
  initialState: INITIAL_SERIES_STATE,
  reducers: {
    setItem: (
      state,
      action: PayloadAction<{ slug: SlugValue; data: Series }>
    ) => {
      const value = state.items[action.payload.slug];
      const cacheUntil = dayjs().add(1, 'day').valueOf();
      if (value) {
        value.cacheUntil = cacheUntil;
        value.loadingStatus = EntityLoadingStatus.LOADED;
        value.item = action.payload.data;
        value.error = null;
      } else {
        state.items[action.payload.slug] = {
          key: action.payload.slug,
          loadingStatus: EntityLoadingStatus.LOADED,
          cacheUntil,
          item: action.payload.data,
          error: null,
        };
      }
    },
    setRequesting: (state, action: PayloadAction<{ slug: SlugValue }>) => {
      const value = state.items[action.payload.slug];
      if (value) {
        value.loadingStatus = value.item
          ? EntityLoadingStatus.REFRESHING
          : EntityLoadingStatus.LOADING;
        value.error = null;
      } else {
        state.items[action.payload.slug] = {
          key: action.payload.slug,
          loadingStatus: EntityLoadingStatus.LOADING,
          cacheUntil: undefined,
          item: undefined,
          error: null,
        };
      }
    },

    setError: (
      state,
      action: PayloadAction<{ slug: SlugValue; error: string }>
    ) => {
      const value = state.items[action.payload.slug];
      if (value) {
        value.loadingStatus = EntityLoadingStatus.ERROR;
        value.error = action.payload.error;
      } else {
        state.items[action.payload.slug] = {
          key: action.payload.slug,
          loadingStatus: EntityLoadingStatus.ERROR,
          cacheUntil: undefined,
          item: undefined,
          error: action.payload.error,
        };
      }
    },
  },
});

export const { setItem, setRequesting, setError } = seriesSlice.actions;

export default seriesSlice.reducer;
