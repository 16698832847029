import { ContentType } from '../../../../../content/data/content-type';
import mapToAdultThemes from '../../../../../shared/api/adapters/map-to-adult-themes';
import mapToHealthGuidance from '../../../../../shared/api/adapters/map-to-health-guidance';
import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../shared/api/adapters/map-to-related-content-slugs';
import toTitleCase from '../../../../../utils/to-title-case';
import type { Workshop } from '../../../../data/workshop';

export function mapSdkToWorkshop(contentfulWorkshop: any): Workshop {
  const {
    fields: {
      name,
      slug,
      type,
      shortIntroduction,
      longIntroduction,
      workshopMedia,
      mainImage,
      relatedProductionCompanies,
      relatedContent,
      relatedSegments,
      relatedContentGuidance,
      workshopDate,
      relatedSkills,
      relatedGenres,
      relatedThemes,
      relatedEducationLevels,
      relatedPeople,
    },
    sys: { id, createdAt },
  } = contentfulWorkshop;

  const {
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    id,
    name,
    slug,
    type: ContentType.WORKSHOP,
    subType: type,
    image: mapSdkToImage(mainImage),
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    media: {
      // eslint-disable-next-line no-underscore-dangle
      type: toTitleCase(workshopMedia.sys.contentType.sys.id) as
        | 'Video'
        | 'Audio',
      assetId: workshopMedia.fields.assetId ?? null,
      dashManifestUrl: workshopMedia.fields.dashManifestUrl ?? null,
      hlsManifestUrl: workshopMedia.fields.hlsManifestUrl ?? null,
      subtitleUrl: workshopMedia.fields.subtitleUrl ?? null,
      durationMinutes: workshopMedia.fields.durationMinutes ?? null,
    },
    firstPublishedAt: createdAt?.substring(0, 10) ?? null,
    producerNames:
      relatedProductionCompanies?.map((company) => company.fields.name) ?? [],
    relatedProductionSlugs,
    relatedInterviewSlugs,
    relatedWorkshopSlugs,
    relatedGuideSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
    relatedSegmentIds: relatedSegments?.map(({ sys }) => sys.id) ?? [],
    relatedSkillsSlugs: relatedSkills?.map((skill) => skill.fields.slug) ?? [],
    relatedGenresSlugs: relatedGenres?.map((genre) => genre.fields.slug) ?? [],
    relatedThemesSlugs: relatedThemes?.map((theme) => theme.fields.slug) ?? [],
    relatedEducationLevelNames:
      relatedEducationLevels?.map((level) => level.fields.name) ?? [],
    relatedPeopleSlugs:
      relatedPeople?.map((people) => people.fields.slug) ?? [],
    date: workshopDate ?? null,
    hasAdultThemes: mapToAdultThemes(relatedContentGuidance),
    healthGuidance: mapToHealthGuidance(relatedContentGuidance),
  };
}
