import type { Company } from '../../data/company';

export function mapSdkToCompany(contentfulCompany: any): Company {
  const {
    fields: { name, slug },
    sys: { id },
  } = contentfulCompany;

  return {
    id,
    name,
    slug,
  };
}
