import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Link from 'next/link';
import { FC, ReactElement, useMemo } from 'react';
import { latestSubscriptionByTypeSelector } from '../../../auth/utils/subscriptions';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { isTrialSubscriptionSelector } from '../../../store/user/selectors';
import NotificationBanner from './notification-banner';

dayjs.extend(LocalizedFormat);

const TrialUserFeedback: FC = (): ReactElement | null => {
  const isTrialUser = useTypedSelector(isTrialSubscriptionSelector);

  const latestTrialSubscription = useTypedSelector((state) =>
    latestSubscriptionByTypeSelector(state, 'trial')
  );

  const requestingFeedback = useMemo(() => {
    if (latestTrialSubscription) {
      return latestTrialSubscription.requestFeedback;
    }
    return false;
  }, [latestTrialSubscription]);

  const endDate = useMemo(() => {
    if (latestTrialSubscription) {
      const formattedDate = dayjs(latestTrialSubscription.endDate).format(
        'dddd, LL'
      );
      const today = dayjs().format('dddd, LL');
      const tomorrow = dayjs().add(1, 'day').format('dddd, LL');

      if (formattedDate === today) {
        return 'today';
      }

      if (formattedDate === tomorrow) {
        return 'tomorrow';
      }

      return `on ${formattedDate}`;
    }

    return '';
  }, [latestTrialSubscription]);

  return (
    <NotificationBanner
      name="trialFeedback"
      dismissable
      bottom
      active={isTrialUser}
      ariaLabel="Trial Feedback"
    >
      {requestingFeedback && (
        <>
          {' '}
          Enjoying your trial?{' '}
          <Link href="/feedback">
            <a>Click here to share your feedback.</a>
          </Link>
          <br />
        </>
      )}
      Your Digital Theatre+ trial ends {endDate}. If you require any assistance,
      please contact{' '}
      <a href="mailto:support@digitaltheatreplus.com?subject=Help%20with%20trial%20expiring%20soon">
        support@digitaltheatreplus.com.
      </a>
    </NotificationBanner>
  );
};

export default TrialUserFeedback;
