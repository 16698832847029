import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { mapToRelatedContentSlugs } from '../../../../../shared/api/adapters/map-to-related-content-slugs';
import type { Title } from '../../../../data/title';

export function mapSdkResponseToTitle(contentfulTitle: any): Title {
  const {
    sys: { id, createdAt },
    fields: {
      name,
      mainImage,
      shortIntroduction,
      longIntroduction,
      primaryPeople,
      relatedGenres,
      relatedContent,
      relatedEducationLevels,
      slug,
    },
  } = contentfulTitle;

  const {
    relatedGuideSlugs,
    relatedInterviewSlugs,
    relatedLessonPlanSlugs,
    relatedProductionSlugs,
    relatedWorkshopSlugs,
    relatedMultipleSeriesSlugs,
  } = mapToRelatedContentSlugs(relatedContent);

  return {
    name,
    slug,
    id,
    firstPublishedAt: createdAt ? createdAt.substring(0, 10) : null,
    image: mapSdkToImage(mainImage),
    genre: relatedGenres?.map((genre) => genre.fields.name) ?? [],
    primaryPeopleSlugs: primaryPeople
      ? primaryPeople.map(({ fields }) => fields.slug)
      : [],
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    relatedGuideSlugs,
    relatedInterviewSlugs,
    relatedProductionSlugs,
    relatedWorkshopSlugs,
    relatedLessonPlanSlugs,
    relatedMultipleSeriesSlugs,
    relatedEducationLevelNames:
      relatedEducationLevels?.map((l) => l.fields.name) || [],
  };
}
