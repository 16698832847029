import type { RootState } from '..';
import type { Subscription } from '../../auth/data/user';
import type { UserState } from './user.state';
import type { Bookmark } from '../../bookmarks';

export const userSelector = (state: RootState): UserState => state.user;

export const userIdSelector = (state: RootState): string => state.user.id;

export const userNameSelector = (state: RootState): string => state.user.name;

export const masterAccountIdSelector = (state: RootState): number =>
  state.user.masterAccount.id;

export const organisationIdSelector = (state: RootState): string =>
  state.user.organisation.id;

export const organisationNameSelector = (state: RootState): string =>
  state.user.organisation.name;

export const organisationCategorySelector = (state: RootState): string =>
  state.user.organisation.category;

export const organisationCountrySelector = (state: RootState): string =>
  state.user.organisation.country;

export const organisationCountryAlpha3Selector = (state: RootState): string =>
  state.user.organisation.country_alpha3;

export const organisationBlockedProfilesSelector = (
  state: RootState
): string[] => state.user.organisation.blockedProfiles;

export const organisationAuthMethodSelector = (state: RootState): string =>
  state.user.organisation.authMethod;

export const adultThemesRestrictedSelector = (state: RootState): boolean =>
  state.user.organisation.adultThemesRestricted;

export const hasActiveTrialSubscriptionSelector = (state: RootState): boolean =>
  state.user.subscriptionStatus.hasActiveTrialSubscription;

export const hasActiveFullSubscription = (state: RootState): boolean =>
  state.user.subscriptionStatus.hasActiveFullSubscription;

export const isTrialSubscriptionSelector = (state: RootState): boolean =>
  !state.user.subscriptionStatus.hasActiveFullSubscription &&
  state.user.subscriptionStatus.hasActiveTrialSubscription;

export const subscriptionsSelector = (state: RootState): Subscription[] =>
  state.user.subscriptions;

export const administratorPermissionsSelector = (state: RootState): string[] =>
  state.user.permissions.administrator;

export const alwaysAllowedSelector = (state: RootState): string[] =>
  state.user.alwaysAllowed;

export const alwaysBlockedSelector = (state: RootState): string[] =>
  state.user.alwaysBlocked;

export const isSuperuserSelector = (state: RootState): boolean =>
  state.user.permissions.superuser;

export const isAdminSelector = (state: RootState): boolean =>
  state.user.permissions.superuser ||
  state.user.permissions.administrator.length > 0;

export const bookmarksSelector = (state: RootState): Array<Bookmark> =>
  state.user.bookmarks;
