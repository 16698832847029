import type { Series } from '../../series/data/series';
import type { SlugValue } from '../../shared/hooks/use-slug';
import type { EntityWrapper } from '../state/entity-wrapper';

export interface SeriesState {
  items: { [key: SlugValue]: EntityWrapper<Series> };
}

export const INITIAL_SERIES_STATE: SeriesState = {
  items: {},
};
