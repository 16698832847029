import { getTotalNumberOfInterviewsFromContentfulViaGql } from './adapters/contentful-interviews-content-gql-api';
import { getContentfulClient } from '../../../config/contentful-config';
import {
  getInterviewFromContentful,
  getInterviewsForMultipleSlugsFromContentful,
  newGetInterviewNameFromContentful,
} from './adapters/contentful-interviews-content-sdk-api';
import type { GetContentNameBySlug } from '../../../auth/utils/authentication';
import type { GetInterview, GetInterviewsForMultipleSlugs } from './types';

export const getInterview: GetInterview = getInterviewFromContentful;

export const getInterviewName: GetContentNameBySlug =
  newGetInterviewNameFromContentful(getContentfulClient());

export const getInterviewsForMultipleSlugs: GetInterviewsForMultipleSlugs =
  getInterviewsForMultipleSlugsFromContentful;

export const getTotalNumberOfInterviews =
  getTotalNumberOfInterviewsFromContentfulViaGql;
