import { getContentfulClient } from '../../config/contentful-config';
import type { Interview } from '../../interviews/data/interview';
import type { Workshop } from '../../workshops/data/workshop';
import type { Content } from '../../content/data/content';
import { newGetNumberOfInterviewsAndWorkshopsAssociatedWithContentFromContentfulViaSdk } from './adapters/contentful-get-interviews-and-workshops-with-content';
import { transformSlugsToIdsFromContentful } from './adapters/contentful-transform-slugs-to-ids';
import { getMyIpAddressFromDt } from './adapters/get-my-ip-address';
import { getUsatSurveyMetadataFromApi } from './adapters/get-usat-survey-metadata';
import { getSegmentsByIdsFromContentful } from './adapters/segments';
import { getRelatedSeriesFromContentful } from './adapters/series/contentful-get-related-series';
import { submitUserSurveyToApi } from './adapters/submit-user-survey';
import { setUsatSurveyMetadataWithApi } from './adapters/set-usat-survey-metadata';
import { submitFeedbackToApi } from './adapters/submit-feedback';
import { getGranularContentControlsFromUsersApi } from './adapters/customers-api/get-granular-content-controls';
import { getSeriesRelatedContentFromContentful } from './adapters/content/get-content';
import { newGetInterviewsAndWorkshopsForMultipleSlugsFromContentful } from './adapters/contentful-interviews-and-workshops-content-api';
import getContentFromBff from '../../content/factories/get-content-from-bff';
import { getCompaniesForMultipleSlugsFromContentful } from './adapters/contentful-company-content-api';
import { getPeriodsForMultipleSlugsFromContentful } from './adapters/contentful-period-content-api';
import { mutateUserNotificationsDataFromCustomerGql } from './backend/adapters/mutate-user-notifications-data';
import { getPdfExtractsByIdsFromContentful } from './adapters/pdf-extracts';
import type {
  GetCompaniesForMultipleSlugs,
  GetGranularContentControls,
  GetInterviewsAndWorkshops,
  GetInterviewsAndWorkshopsForMultipleSlugs,
  GetMyIpAddress,
  GetNumberOfInterviewsAndWorkshopsAssociatedWithContent,
  GetPdfExtractsByIds,
  GetPeriodsForMultipleSlugs,
  GetRelatedSeries,
  GetSegmentsByIds,
  GetUsatSurveyMetadata,
  SetUsatSurveyMetadata,
  SubmitFeedback,
  SubmitUserSurvey,
  TransformSlugsToIds,
} from './types';

export const getNumberOfInterviewsAndWorkshopsAssociatedWithContent: GetNumberOfInterviewsAndWorkshopsAssociatedWithContent =
  newGetNumberOfInterviewsAndWorkshopsAssociatedWithContentFromContentfulViaSdk(
    getContentfulClient()
  );

export const getInterviewsAndWorkshops: GetInterviewsAndWorkshops =
  getContentFromBff<Interview | Workshop>('interviews-and-workshops');

export const transformSlugsToIds: TransformSlugsToIds =
  transformSlugsToIdsFromContentful;

export const searchContent = getContentFromBff<Content>('search');

export const getSegmentsByIds: GetSegmentsByIds =
  getSegmentsByIdsFromContentful;

export const getPdfExtractsByIds: GetPdfExtractsByIds =
  getPdfExtractsByIdsFromContentful;

export const getRelatedSeries: GetRelatedSeries =
  getRelatedSeriesFromContentful;

export const getMyIpAddress: GetMyIpAddress = getMyIpAddressFromDt;

export const submitFeedback: SubmitFeedback = submitFeedbackToApi;

export const submitUserSurvey: SubmitUserSurvey = submitUserSurveyToApi;

export const setUsatSurveyMetadata: SetUsatSurveyMetadata =
  setUsatSurveyMetadataWithApi;

export const getUsatSurveyMetadata: GetUsatSurveyMetadata =
  getUsatSurveyMetadataFromApi;

export const getGranularContentControls: GetGranularContentControls =
  getGranularContentControlsFromUsersApi;

export const getSeriesRelatedContent = getSeriesRelatedContentFromContentful;

export const getInterviewsAndWorkshopsForMultipleSlugs: GetInterviewsAndWorkshopsForMultipleSlugs =
  newGetInterviewsAndWorkshopsForMultipleSlugsFromContentful(
    getContentfulClient()
  );

export const getCompaniesForMultipleSlugs: GetCompaniesForMultipleSlugs =
  getCompaniesForMultipleSlugsFromContentful(getContentfulClient());

export const getPeriodsForMultipleSlugs: GetPeriodsForMultipleSlugs =
  getPeriodsForMultipleSlugsFromContentful(getContentfulClient());

export const mutateUserNotificationsData =
  mutateUserNotificationsDataFromCustomerGql;
