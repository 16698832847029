import type { ContentfulClientApi } from 'contentful';

import type { GetGuide } from '../types';

import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';

import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import type { Guide } from '../../../data/guide';

import mapSdkToGuide from './mappers/map-sdk-to-guide';
import toContentfulOrdering from '../../../../utils/to-contentful-ordering';
import { getContentfulClient } from '../../../../config/contentful-config';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export function newGetGuidesForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: any): Promise<Guide[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GUIDE,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const { items } = response;

    const mappedGuides = items.map(mapSdkToGuide);

    const orderedGuides = toContentfulOrdering(slugs, mappedGuides);

    return orderedGuides;
  };
}

export const getGuideFromContentful: GetGuide = async (slug) => {
  const guides = await newGetGuidesForMultipleSlugsFromContentful(
    getContentfulClient()
  )([slug]);

  if (guides.length === 0) {
    throw Error('Invalid or missing slug');
  }

  return guides[0];
};

export function newGetGuideNameFromContentful(
  contentfulClient: ContentfulClientApi
): GetContentNameBySlug {
  return async (slug: string, filters: Filters): Promise<string> => {
    const rightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );

    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GUIDE,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        'fields.relatedRightsAgreement.sys.id[nin]': rightsAgreementIds,
        select: 'fields.name',
      },
      contentfulClient
    );

    const { items } = response;

    return items[0].fields.name;
  };
}

export function newGetTotalNumberOfGuidesFromContentful(
  client: ContentfulClientApi
) {
  return async (): Promise<number> => {
    const { total } = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GUIDE,
        limit: 0,
      },
      client
    );

    return total;
  };
}
