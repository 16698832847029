import { getEntries } from '../../../../respositories/frontend/contentful-repository';
import { mapSdkToSeries } from '../../../../series/api/frontend/adapters/mappers/map-sdk-to-series';
import type { GetRelatedSeries } from '../../types';

export const getRelatedSeriesFromContentful: GetRelatedSeries = async (
  id: string
) => {
  const contentfulSeries = await getEntries({
    content_type: 'series',
    'fields.relatedItems.sys.id': id,
    include: 1,
  });

  return contentfulSeries.items.map(mapSdkToSeries);
};
