/* eslint-disable no-console */
import Cookie from 'js-cookie';
import type {
  OktaAuth,
  TokenManagerError,
  UserClaims,
} from '@okta/okta-auth-js';
import URI from 'urijs';
import type { NextRouter } from 'next/router';
import { ConsoleLogger } from '../../shared/services/logging/console-logger';
import type { Logger } from '../../shared/services/logging/logger';

/**
 * Auth service allows us to monitor and use the Okta authentication with Next.js
 *
 * This monitors the JWT in localstorage and checks whether it is valid.
 * If it is, it adds it to a cookie so that we can authenticate against it server side.
 */
class AppAuthService {
  oktaAuthService: OktaAuth;
  logger: Logger = new ConsoleLogger();

  constructor(authService: OktaAuth, private readonly router: NextRouter) {
    this.oktaAuthService = authService;
    const { tokenManager } = this.oktaAuthService;

    if (tokenManager !== undefined) {
      // This is false on SSR, where this code does run
      tokenManager.off('expired');
      tokenManager.off('renewed');
      tokenManager.off('error');

      // Triggered when a token has expired
      tokenManager.on(
        'expired',
        (key, expiredToken) => {
          this.onTokenExpired(key, expiredToken);
        },
        this
      );
      // Triggered when a token has been renewed
      tokenManager.on(
        'renewed',
        (key, newToken, oldToken) => {
          this.onTokenRenewed(key, newToken, oldToken);
        },
        this
      );
      // Triggered when an OAuthError is returned via the API (typically during auto-renew)
      tokenManager.on(
        'error',
        (err) => {
          this.onTokenError(err);
        },
        this
      );
    }
  }

  async login(redirect = '/'): Promise<void> {
    return this.oktaAuthService.signInWithRedirect({ originalUri: redirect });
  }

  logout(message?: string): void {
    AppAuthService.removeTokenCookie();
    const formattedUri = URI(`${window.location.origin}/logged-out`);
    if (message) {
      formattedUri.addQuery('message', message);
    }

    const signOutObj = {
      postLogoutRedirectUri: formattedUri.toString(),
      clearTokensBeforeRedirect: true,
    };
    if (message === 'session_expired') {
      Object.assign(signOutObj, { state: this.router.asPath });
    }

    this.oktaAuthService.signOut(signOutObj);
  }

  /**
   * We subscribe to refresh events on the token manager
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTokenRenewed(key, _newToken, _oldToken): void {
    // Todo: #341
    this.logger.log(`Token with key ${key} has been renewed`);
  }

  /**
   * This is called before the token actually expires by a configurable amount
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTokenExpired(key, _expiredToken): void {
    // Todo: #341
    this.logger.log(`Token with key ${key} has expired:`);
  }

  /**
   * Something has gone wrong with the token
   */
  onTokenError(error: TokenManagerError): void {
    if (error.errorCode === 'login_required') {
      // The Okta session has expired or was closed outside the application
      // The application should return to an unauthenticated state
      this.login(this.router.asPath);
    }
  }

  static removeTokenCookie(): void {
    Cookie.remove('jwt');
  }

  async getUserInfo(): Promise<UserClaims | null> {
    // Route via the Okta implementation of the userinfo fetching
    const userInfo = await this.oktaAuthService.getUser();
    if (userInfo === undefined) {
      return null;
    }

    return userInfo;
  }
}

export default AppAuthService;
