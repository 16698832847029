import URI from 'urijs';
import fetch from 'isomorphic-unfetch';
import { getActiveSubscriptionsByType } from '../../../auth/utils/subscriptions';
import type { SubmitUserSurvey } from '../types';

export const submitUserSurveyToApi: SubmitUserSurvey = async (user, score) => {
  const submitUserSurveyUri = new URI(
    `${window.location.origin}/api/user-survey`
  ).toString();

  const fullSubscriptions = getActiveSubscriptionsByType(
    user.subscriptions,
    'full'
  );

  const endDate = fullSubscriptions[0]?.endDate;
  const startDate = fullSubscriptions[0]?.startDate;

  const userSurvey = {
    userId: user.id,
    organisationId: user.organisation.id,
    parentCompanyId: user.masterAccount.id,
    organisationName: user.organisation.name,
    parentCompanyName: user.masterAccount.name,
    subscriptionStart: startDate,
    subscriptionEnd: endDate,
    score,
  };

  const response = await fetch(submitUserSurveyUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userSurvey),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    throw new Error(jsonResponse.error.message);
  }
};
