import { createClient } from 'contentful';
import type { ContentfulClientApi } from 'contentful';
import URI from 'urijs';

export const CONTENTFUL_API_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY || 'missing_api_token';
export const CONTENTFUL_SPACE =
  process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || 'missing_space_id';
export const CONTENTFUL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || 'dev';
export const CONTENTFUL_BASE_URL = process.env.NEXT_PUBLIC_CONTENTFUL_BASE_URL;

const baseUrl = URI(CONTENTFUL_BASE_URL);

export const CONTENTFUL_URL = URI(baseUrl)
  .segment('spaces')
  .segment(CONTENTFUL_SPACE)
  .segment('environments')
  .segment(CONTENTFUL_ENVIRONMENT)
  .addSearch('access_token', CONTENTFUL_API_TOKEN)
  .toString();

const client = createClient({
  environment: CONTENTFUL_ENVIRONMENT,
  space: CONTENTFUL_SPACE,
  accessToken: CONTENTFUL_API_TOKEN,
  removeUnresolved: true,
});

export function getContentfulClient(): ContentfulClientApi {
  return client;
}

// Code snippet for logging when getEntries is called
// export function getContentfulClient(): ContentfulClientApi {
//   return {
//     ...client,
//     getEntries: async (...args) => {
//       console.log('CONTENTFUL API CALL');
//       return client.getEntries(args);
//     },
//   };
// }
