import type { ActionDimensions } from '../data/events';

const dimensionsToDimensionIds = {
  contentId: 'dimension8',
  attributedTo: 'dimension9',
  contentType: 'dimension10',
  contentSubtype: 'dimension13',
  cardTitle: 'dimension14',
  cardIndex: 'dimension15',
  durationMinutes: 'dimension16',
  clickedFromPageType: 'dimension17',
  clickedFromPageTitle: 'dimension18',
  clickedFromLocation: 'dimension19',
  clickedFromUrl: 'dimension20',
};

export const normaliseDimensions = (actionDimensions: ActionDimensions) =>
  Object.entries(actionDimensions).reduce((acc, [key, value]) => {
    if (dimensionsToDimensionIds[key]) {
      acc[dimensionsToDimensionIds[key]] = value;
    }
    return acc;
  }, {});
