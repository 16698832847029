import fetch from 'isomorphic-unfetch';
import URI from 'urijs';
import type { UsatSurveyMetadataDto } from '../../data/usat-survey-metadata';
import type { SetUsatSurveyMetadata } from '../types';

export const setUsatSurveyMetadataWithApi: SetUsatSurveyMetadata = async ({
  userId,
  nextDisplayTimestamp,
  dismissalCounter,
}) => {
  const url = new URI(
    `${window.location.origin}/api/user-survey-metadata`
  ).toString();

  const usatSurveyMetadataDto: UsatSurveyMetadataDto = {
    userId,
    nextDisplayTimestamp: nextDisplayTimestamp.valueOf(),
    dismissalCounter,
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(usatSurveyMetadataDto),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    throw new Error(jsonResponse.error.message);
  }
};
