import { QueryClient } from 'react-query';
import type { Genre } from '../genres/data/genre';
import type { Guide } from '../guides/data/guide';
import type { Interview } from '../interviews/data/interview';
import type { LessonPlan } from '../lesson-plans/data/lesson-plan';
import type { Person } from '../people/data/person';
import type { Production } from '../productions/data/production';
import type { CoreContent } from '../shared/data/generic';
import type { Theme } from '../themes/data/theme';
import type { Title } from '../titles/data/title';
import type { Workshop } from '../workshops/data/workshop';
import type { Company } from '../shared/data/company';
import type { Period } from '../shared/data/period';
import type { Role } from '../people/data/role';
import type { Skill } from '../skills/data/Skill';
import type { EducationLevel } from '../education-levels/data/education-level';
import type { ResourceGroupedExtract } from '../content/data/extract';
import type { Content } from '../content/data/content';

const FIFTEEN_MINUTES = 1000 * 60 * 15;

type ContentType =
  | 'title'
  | 'genre'
  | 'theme'
  | 'person'
  | 'production'
  | 'interview'
  | 'workshop'
  | 'guide'
  | 'lessonPlan'
  | 'series'
  | 'company'
  | 'period'
  | 'role'
  | 'skill'
  | 'educationLevel'
  | 'studyUnit'
  | 'studyUnitModule';

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

export const getSlugCacheKey = (type: ContentType, slug: string) => [
  type,
  slug,
];

const generateCacheContent = <T extends CoreContent>(
  contentType: ContentType
) => {
  const cacheContent = (content: T) =>
    queryClient.setQueryData(
      getSlugCacheKey(contentType, content.slug),
      content
    );
  return cacheContent;
};

export const cacheTitle = generateCacheContent<Title>('title');
export const cachePerson = generateCacheContent<Person>('person');
export const cacheProduction = generateCacheContent<Production>('production');
export const cacheInterview = generateCacheContent<Interview>('interview');
export const cacheWorkshop = generateCacheContent<Workshop>('workshop');
export const cacheGuide = generateCacheContent<Guide>('guide');
export const cacheLessonPlan = generateCacheContent<LessonPlan>('lessonPlan');
export const cacheGenre = generateCacheContent<Genre>('genre');
export const cacheTheme = generateCacheContent<Theme>('theme');
export const cacheCompany = generateCacheContent<Company>('company');
export const cachePeriod = generateCacheContent<Period>('period');
export const cacheRole = generateCacheContent<Role>('role');
export const cacheSkill = generateCacheContent<Skill>('skill');
export const cacheEducationLevel =
  generateCacheContent<EducationLevel>('educationLevel');

export function cacheContentForSection(
  sectionSlug: string,
  content: (Content | ResourceGroupedExtract)[]
) {
  queryClient.setQueryData(['contentForSection', sectionSlug], content);
}

export const getPlaceholderData = <T>(
  type: ContentType,
  slugs: string[]
): T[] =>
  slugs
    .map((slug) => getSlugCacheKey(type, slug))
    .map((key) => queryClient.getQueryData(key))
    .filter((data): data is T => Boolean(data));

export const getCacheData = <T>(key) => {
  return queryClient.getQueryData(key) as T;
};

export const cacheRequest = <T extends string | number | boolean, R>(
  target: (arg: T) => Promise<R>,
  key: string[]
): ((arg: T) => Promise<R>) => {
  return (arg: T): Promise<R> => {
    return queryClient.fetchQuery(
      [...key, arg],
      () => {
        return target(arg);
      },
      { staleTime: FIFTEEN_MINUTES }
    );
  };
};
