import type { SCThemeDefinition } from './theme-definition';

export const SCTheme: SCThemeDefinition = {
  colors: {
    backgrounds: {
      default: 'white',
      selectedItem: 'lightgrey',
    },
  },
  accounts: {
    actionColor: '#007ec0',
    contentRestrictionStatus: {
      backgroundColor: '#007ec0',
    },
    dividerColor: 'rgba(231, 230, 230)',
  },
};
