import type { ContentfulClientApi } from 'contentful';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';
import type { GetNumberOfProductionsAssociatedWithContent } from '../types';

export const newGetNumberOfProductionsAssociatedWithContent = (
  client: ContentfulClientApi
): GetNumberOfProductionsAssociatedWithContent => {
  return async (content, associatedContentType): Promise<number> => {
    const productionsWithContent = await getEntries(
      {
        content_type: 'production',
        [associatedContentType]: content.id,
        limit: 0,
      },
      client
    );
    return productionsWithContent.total;
  };
};
