import fetch from 'isomorphic-unfetch';
import URI from 'urijs';

import type { SubmitFeedback } from '../types';

export const submitFeedbackToApi: SubmitFeedback = async (
  parentCompanyId: number,
  organisationId: string,
  role: string,
  recommend: number,
  explain: string,
  other: string
) => {
  const submitFeedbackApi = new URI(
    `${window.location.origin}/api/feedback`
  ).toString();

  const feedback = {
    parentCompanyId,
    organisationId,
    role,
    recommend,
    explain,
    other,
  };

  const response = await fetch(submitFeedbackApi, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(feedback),
  });

  if (!response.ok) {
    const jsonResponse = await response.json();
    throw new Error(jsonResponse.error.message);
  }
};
