export enum ContentType {
  PRODUCTION = 'production',
  GUIDE = 'guide',
  INTERVIEW = 'interview',
  WORKSHOP = 'workshop',
  LESSON_PLAN = 'lessonPlan',
  TITLE = 'title',
  PERSON = 'person',
  GENRE = 'genre',
  THEME = 'theme',
  SERIES = 'series',
  STUDY_UNIT = 'studyUnit',
}
