import type { Segment } from '../../../data/segment';
import { mapSdkToSegment } from './map-to-segment';
import type { Content } from '../../../../content/data/content';
import { mapContentfulEntriesToContents } from '../search-api/map-contentful-to-domain-models';
import type { GetSegmentsByIds } from '../../types';
import { getEntriesIsomorphic } from '../../../../respositories/isomorphic/contentful-repository';
import { getContentfulClient } from '../../../../config/contentful-config';

export const getSegmentsByIdsFromContentful: GetSegmentsByIds = async (
  ids: string[]
): Promise<Segment[]> => {
  const response = await getEntriesIsomorphic(
    {
      content_type: 'segment',
      'sys.id[in]': ids.join(),
    },
    getContentfulClient()
  );

  const segments = response.items.map(mapSdkToSegment);

  return ids.map((id) =>
    segments.find((segment) => segment.id === id)
  ) as Segment[];
};

export async function getSegmentContent(
  segmentId: string,
  countryCode: string | null,
  blockedProfiles: string[] | null
): Promise<Content | null> {
  const client = getContentfulClient();

  const content = await Promise.allSettled([
    getEntriesIsomorphic(
      {
        content_type: 'production',
        'fields.relatedSegments.sys.id[in]': segmentId,
      },
      client,
      {
        countryCode,
        blockedProfiles,
      }
    ),
    getEntriesIsomorphic(
      {
        content_type: 'interview',
        'fields.relatedSegments.sys.id[in]': segmentId,
      },
      client,
      {
        countryCode,
        blockedProfiles,
      }
    ),
    getEntriesIsomorphic(
      {
        content_type: 'workshop',
        'fields.relatedSegments.sys.id[in]': segmentId,
      },
      client,
      {
        countryCode,
        blockedProfiles,
      }
    ),
  ]);

  const results = content.flatMap((r) => {
    if (r.status === 'fulfilled') {
      return r.value.items;
    }
    return [];
  });

  const mappedContent = mapContentfulEntriesToContents(results);

  if (mappedContent.length === 0) {
    return null;
  }
  return mappedContent[0];
}
